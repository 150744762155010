import React, { useEffect, useState, useContext } from 'react';
import DetailsMaster from '../Config';
import * as styles from './Lessons.module.scss';
import { Lesson } from '../../../Classes/Lesson';
import { Course } from '../../../Classes/Course';
import { CourseModule } from '../../../Classes/CourseModule';
import { Link, navigate } from 'gatsby';
import { OrganisationContext } from '../../../../../../Context/UserContext';
import { Panel, Table } from '../../../Components';
import { DecryptValue, EncryptValue } from '../../../../../../Services/Crypto';

const ModuleLessons: React.FC = (props: any) => {
  const ModuleID: string = props.ModuleID ? props.ModuleID : "";

  const { Organisation } = useContext(OrganisationContext);
  const [Lessons, setLessons] = useState([] as Lesson[]);
  const [Module, setModule] = useState({} as CourseModule);

  useEffect(() => {
    if (Organisation.Employees)
      GetLessons()
  }, [Organisation])

  const GetIcon = () => {
    let items: JSX.Element[] = [];

    Lessons.map((lesson) => {
      items.push(<Link to={`/Admin/Lesson/Details/${EncryptValue(lesson.ID.toString())}`} className={styles.Link}>
        {lesson.Title}
      </Link>)
    })

    return items
  }

  const GetSlides = () => {
    let items: JSX.Element[] = [];

    Lessons.map((lesson) => {
      items.push(<div className={styles.Status}>
        <Link to={`/Admin/Lesson/Details/${EncryptValue(lesson.ID.toString())}/Builder`}>
          {lesson.Slides.length} Slide{lesson.Slides.length === 1 ? "" : "s"}
        </Link>
      </div>)
    })

    return items
  }

  const GetLessons = () => {
    let course: Course | undefined = Organisation.Courses.find(c => c.Modules?.find(m => m.ID === parseInt(DecryptValue(ModuleID))));
    if (course) {
      let module: CourseModule | undefined = course.Modules?.find(m => m.ID === parseInt(DecryptValue(ModuleID)));
      if (module) {
        setModule(module);
        setLessons(module.Lessons);
      }
    }
  }

  return (Module.ID ? <DetailsMaster ModuleID={parseInt(DecryptValue(ModuleID))} Page="Lessons">
    <Panel Footer={false} Title="Lessons" Buttons={[{
      Color: "Theme",
      children: <>Add Lesson</>,
      OnClick: () => { navigate(`/Admin/Lesson/Details/${EncryptValue("0")}?ModuleID=${DecryptValue(ModuleID)}`) }
    }]}>
      <Table ShowPagination={true} Data={Lessons} DataHead={[
        {
          Text: "Title",
          Key: "",
          DisplayAs: "custom",
          CustomItem: GetIcon(),
        },
        {
          Text: "Slides",
          Key: "",
          DisplayAs: "custom",
          Align: "center",
          CustomItem: GetSlides(),
        }]} />
    </Panel>
  </DetailsMaster> : <></>);
};

export default ModuleLessons;
