import React, { useEffect, useState } from 'react';
import { Package } from '../../../Classes/Package';
import { Fetch } from '../../../Services/Fetch';
import * as styles from '../AdminBase.module.scss';
import { API_AdminPackages } from '../../../Services/ApiRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../../../components/Loading/Loading';
import { Link } from 'gatsby';

const AdminPackages: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [packages, setPackages] = useState<Package[]>([] as Package[]);

  useEffect(() => {
    document.title = "PrimeHR :: Admin";

    Fetch(`${API_AdminPackages}`)
      .then((Response: Package[]) => {
        if (Response) {
          setPackages(Response);
          setLoading(false);
        }
      })
  }, [])

  return (<>
    <div className={styles.Search}>
      <div className={styles.SearchInput}>
        <i><FontAwesomeIcon icon={faSearch} /></i>
        <input type="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
    </div>

    <div className={styles.Table}>
      <div className={styles.Header}>
        <div className={styles.Cell}>
          <p>Title</p>
        </div>
        <div className={styles.Cell}>
          <p>1 Month Cost</p>
        </div>
        <div className={styles.Cell}>
          <p>3 Month Cost</p>
        </div>
        <div className={styles.Cell}>
          <p>6 Month Cost</p>
        </div>
        <div className={styles.Cell}>
          <p>12 Month Cost</p>
        </div>
        <div className={styles.Cell}>
          <p>Default Package</p>
        </div>
      </div>
      {loading ? <Loading /> : <div className={styles.Body}>
        {
          packages.filter(p => p.Title.toLowerCase().includes(search.toLowerCase())).map(pack => {
            return <Link to={`/Admin/Package/Edit?id=${pack.ID}`} className={styles.Row}>
              <div className={styles.Cell}>
                <p>{pack.Title}</p>
              </div>
              <div className={styles.Cell}>
                <p>{!pack.isFree ? pack.Cost : ""}</p>
              </div>
              <div className={styles.Cell}>
                <p>{!pack.isFree ? pack.Cost3 : ""}</p>
              </div>
              <div className={styles.Cell}>
                <p>{!pack.isFree ? pack.Cost6 : ""}</p>
              </div>
              <div className={styles.Cell}>
                <p>{!pack.isFree ? pack.Cost12 : ""}</p>
              </div>
              <div className={styles.Cell}>
                <p>{pack.isDefault ? <i><FontAwesomeIcon icon={faCheckDouble} /></i> : ""}</p>
              </div>
            </Link>
          })
        }
      </div>}
    </div>
  </>);
};

export default AdminPackages;
