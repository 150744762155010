import React, { useEffect, useState } from 'react';
import { Package } from '../../../Classes/Package';
import { Fetch } from '../../../Services/Fetch';
import * as styles from '../AdminBase.module.scss';
import { API_AdminPackages } from '../../../Services/ApiRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../../../components/Loading/Loading';
import { Section } from '../../../Classes/Section';
import { Document as DocProps } from "../../../Classes/Document";
import { Link } from 'gatsby';

const AdminSections: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [sections, setSections] = useState<Section[]>([] as Section[]);
  const [loading, setLoading] = useState<boolean>(true);
  const [packages, setPackages] = useState<Package[]>([] as Package[]);

  useEffect(() => {
    document.title = "PrimeHR :: Admin";

    Fetch(`${API_AdminPackages}`)
      .then((Response: Package[]) => {
        if (Response) {
          let docs: DocProps[] = [];
          let Sections: Section[] = [];

          setPackages(Response);

          Response.map(p => {
            p.Documents.map((d: DocProps) => {
              d.Package = JSON.parse(JSON.stringify(p));
              d.Package.Documents = [];
              docs.push(d);
            })
          })

          docs.map(d => {
            if (!Sections.find(s => s.ID === d.SectionID)) {
              d.Section.Documents = docs.filter(x => x.SectionID === d.SectionID);
              Sections.push(d.Section);
            }
          })

          setSections(Sections);
          setLoading(false);
        }
      })
  }, [])

  return (<>
    <div className={styles.Search}>
      <div className={styles.SearchInput}>
        <i><FontAwesomeIcon icon={faSearch} /></i>
        <input type="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
    </div>

    <div className={styles.Table}>
      <div className={styles.Header}>
        <div className={styles.Cell}>
          <p>Title</p>
        </div>
        <div className={styles.Cell}>
          <p>Description</p>
        </div>
        <div className={styles.Cell}>
          <p>No. of Documents</p>
        </div>
      </div>
      {loading ? <Loading /> : <div className={styles.Body}>
        {
          sections.filter(s => s.Title.toLowerCase().includes(search.toLowerCase())).map(sec => {
            return <Link to={`/Admin/Section/Edit?id=${sec.ID}`} className={styles.Row}>
              <div className={styles.Cell}>
                <p>{sec.Title}</p>
              </div>
              <div className={styles.Cell}>
                <p>{sec.Description}</p>
              </div>
              <div className={styles.Cell}>
                <p>{sec.Documents.length}</p>
              </div>
            </Link>
          })
        }
      </div>}
    </div>
  </>);
};

export default AdminSections;
