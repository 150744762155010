import React, { useContext, useEffect, useState, FormEvent } from 'react';
import * as styles from './CourseForm.module.scss';
import { API_SaveCourse, API_DeleteCourse } from '../../ApiRoutes';
import { Course } from '../../Classes/Course';
import { CourseModule } from '../../Classes/CourseModule';
import { navigate } from 'gatsby';
import { OrganisationContext, PackageContext, UserContext } from '../../../../../Context/UserContext';
import { ToastContext } from '../../Context/ToastContext';
import { AlertContext } from '../../Context/ModalContext';
import { Fetch } from '../../../../../Services/Fetch';
import { Button } from '../Button/Button';
import { AlertSettings } from '../Alert/Classes/AlertSettings';
import Input from '../../../../../components/Input/input';
import { Loading } from '../../../../../components/Loading/Loading';
import { EncryptValue } from '../../../../../Services/Crypto';
import { faPoundSign, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    CourseID: number;
}

const CourseForm: React.FC<Props> = ({ CourseID }) => {
    const { Organisation, setOrganisation } = useContext(OrganisationContext);
    const { Packages } = useContext(PackageContext);
    const { ToastSettings, setToastSettings } = useContext(ToastContext);
    const { setAlert } = useContext(AlertContext);
    const [saving, setSaving] = useState<boolean>(false);
    const [data, setData] = useState<Course>({
        Title: "",
        Description: "",
        ID: CourseID,
        WebLink: "",
        isPublished: false
    } as Course);

    useEffect(() => {
        if (CourseID && Organisation.Employees)
            GetCourse();
    }, [CourseID, Organisation])

    const GetCourse = () => {
        let course: Course | undefined = Organisation.Courses.find(c => c.ID === CourseID)
        if (course)
            setData(course);
    }

    const SaveCourse = async (Event: FormEvent) => {
        Event.preventDefault();
        setSaving(true);

        if (data.Title) {
            await Fetch(`${API_SaveCourse}`, JSON.parse(JSON.stringify({ ...data, Price: data.Package ? "0" : data.Price })))
                .then((Response: Course) => {
                    Response.Modules = [] as CourseModule[];
                    setToastSettings({
                        isOpen: true,
                        message: "Successfully Saved",
                        duration: 2500,
                        onDidDismiss: () => { setToastSettings({ ...ToastSettings, isOpen: false }) }
                    })
                    if (data.ID) {
                        setOrganisation({
                            ...Organisation,
                            Courses: Organisation.Courses.map(c => {
                                if (c.ID === CourseID)
                                    c = Response
                                return c;
                            })
                        })
                    } else {
                        let courses: Course[] = JSON.parse(JSON.stringify(Organisation.Courses));
                        courses.push(Response);
                        setOrganisation({ ...Organisation, Courses: courses });
                        navigate(`/Admin/Courses/Details/${EncryptValue(Response.ID.toString())}`)
                    }
                }).catch(() => {
                })
        }

        setSaving(false);
    }

    const DeleteCourse = () => {
        Fetch(`${API_DeleteCourse}`, CourseID)
            .then((Response) => {
                let uCourses: Course[] = JSON.parse(JSON.stringify(Organisation.Courses));
                let cIndex: number = uCourses.findIndex(c => c.ID === CourseID);

                if (cIndex !== undefined && cIndex >= 0)
                    uCourses.splice(cIndex, 1);

                setOrganisation({ ...Organisation, Courses: uCourses })

                if (Response)
                    navigate('/Admin/Courses');
            }).catch(() => {
            })
    }

    const DeleteCourseAlert = () => {
        let alert: AlertSettings = {
            Show: true,
            Icon: "exclamation-circle",
            OnConfirm: () => DeleteCourse(),
            Title: "Delete Course",
            SubTitle: "Are you sure you want to PERMENENTLY DELETE this Course?",
        }
        setAlert(alert);
    }

    return (data.ID || CourseID === 0 ? <>
        <form className={styles.Form} onSubmit={(e) => SaveCourse(e)}>
            <div className={styles.FormDetails}>
                <p>This is the basic information required to create a Course.</p>
                <h1>Instructions:</h1>
                <ol>
                    <li>Start by setting the Title for your Course</li>
                    <li>Then setting a Description</li>
                    <li>Optional: Add a link to the Course listing on the website</li>
                    <li>Optional: Add a price for the course. Free Courses can be set to 0. Paid for Courses must be a minimum of £2 (200)</li>
                    <li>OR: Add a Package this course is part of.</li>
                </ol>
            </div>

            <Input Label="Title" Placeholder="..." Type="text" Value={data.Title} OnChange={(v) => { setData({ ...data, Title: v }) }} />
            <Input Label="Description" Placeholder="..." Type="textarea" Value={data.Description} OnChange={(v) => { setData({ ...data, Description: v }) }} />
            <Input Label="Website Link" Placeholder="https://..." Type="text" Value={data.WebLink} OnChange={(v) => { setData({ ...data, WebLink: v }) }} />
            <Input Label="Is this Course Published?" Placeholder="..." Type="checkbox" Value={data.isPublished} OnChange={(v) => { setData({ ...data, isPublished: v }) }} />
            <Input Label="Price (Pence e.g. 100 = £1)" Icon={faPoundSign} Placeholder="" Type="number" Value={data.Price ? data.Price.toString() : "0"} OnChange={(v) => { setData({ ...data, Price: parseInt(v) }) }} />
            <div className={styles.FormDetails}>
                <h1>OR</h1>
            </div>
            <Input OnChange={(v) => setData({ ...data, Package: parseInt(v) })} Placeholder={''} Type={'select'} Label={'Package'} Value={data.Package ? data.Package.toString() : "0"} Options={Packages.map((p) => {
                return {
                    Text: p.Title,
                    Value: p.ID.toString()
                }
            })} />

            <div className={styles.Buttons}>
                {!data.ID ? undefined : <Button Color="Link" OnClick={() => { DeleteCourseAlert() }} Type="button"><p>Delete Course</p></Button>}
                <Button Color="Theme" Type="submit">{saving ? <>
                    <i>
                        <FontAwesomeIcon icon={faSpinner} spin={true} />
                    </i>
                    <p>Saving</p>
                </> : <p>Save</p>}</Button>
            </div>
        </form>
    </> : <Loading />);
};

export default CourseForm