import React, { useState } from 'react';

interface ToastContextData {
    ToastSettings: ToastSettings;
    setToastSettings: (ToastSettings: ToastSettings) => void
}

export const ToastContext = React.createContext<ToastContextData>({
    ToastSettings: {} as ToastSettings,
    setToastSettings: () => { }
});

type ToastSettings = {
    isOpen: boolean;
    onDidDismiss: () => void;
    message: string;
    duration: number;
}

export const ToastComponent: React.FC = ({ children }) => {
    const [ToastSettings, setToastSettings] = useState<ToastSettings>({} as ToastSettings);
    const value = { ToastSettings, setToastSettings };

    return (
        <ToastContext.Provider value={value}>
            {/* <IonToast {...value.ToastSettings} /> */}
            {children}
        </ToastContext.Provider>
    )
}