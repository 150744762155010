import React, { useEffect, useState, useContext } from 'react';
import * as styles from './Lesson.module.scss';
import { Lesson as LessonProps } from '../../Classes/Lesson';
import { Course as CourseProps } from '../../Classes/Course';
import { CourseModule } from '../../Classes/CourseModule';
import CourseSidebar from '../../Components/Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import { OrganisationContext } from '../../../../../Context/UserContext';
import { BreadCrumb, Panel } from '../../Components';
import { EncryptValue } from '../../../../../Services/Crypto';

interface Props {
    LessonID: number;
    Page: "Permissions" | "Details" | "Builder" | "Resources";
    ModuleID?: number;
    children?: any;
}

const DetailsMaster: React.FC<Props> = ({ children, LessonID, Page, ModuleID }) => {
    const { Organisation } = useContext(OrganisationContext);
    const [Lesson, setLesson] = useState<LessonProps>({} as LessonProps);
    const [Module, setModule] = useState<CourseModule>({} as CourseModule);
    const [Course, setCourse] = useState<CourseProps>({} as CourseProps);

    useEffect(() => {
        if (Organisation.Employees)
            if (LessonID && !ModuleID) {
                let lesson: LessonProps | undefined = Organisation.Courses.find(c => c.Modules?.find(m => m.Lessons.find(l => l.ID === LessonID)))?.Modules?.find(m => m.Lessons.find(l => l.ID === LessonID))?.Lessons.find(l => l.ID === LessonID);

                if (lesson) {
                    setLesson(lesson);
                    let module: CourseModule | undefined = Organisation.Courses.find(c => c.Modules?.find(m => m.Lessons.find(l => l.ID === LessonID)))?.Modules?.find(m => m.ID === lesson?.ModuleID);

                    if (module) {
                        setModule(module);
                        let course: CourseProps | undefined = Organisation.Courses.find(c => c.ID === module?.CourseID);

                        if (course)
                            setCourse(course);
                    }
                }
            } else {
                let module: CourseModule | undefined = Organisation.Courses.find(c => c.ID === ModuleID)?.Modules?.find(m => m.ID === ModuleID);

                if (module) {
                    setModule(module);
                    let course: CourseProps | undefined = Organisation.Courses.find(c => c.ID === module?.CourseID);

                    if (course)
                        setCourse(course);
                }
            }
    }, [LessonID, Organisation, ModuleID])

    return (<Panel Footer={false} Title="Lesson Settings" Width="100%" Buttons={[]} Padding="0px">
        <div className={styles.Permissions}>
            <CourseSidebar>
                <div className={styles.SidebarLink}>
                    <Link className={Page === "Details" ? styles.Active : ""} to={`/Admin/Lesson/Details/${EncryptValue(LessonID.toString())}`}>
                        Details
                    </Link>
                </div>

                {LessonID ? <>
                    <div className={styles.SidebarLink}>
                        <Link className={Page === "Builder" ? styles.Active : ""} to={`/Admin/Lesson/Details/${EncryptValue(LessonID.toString())}/Builder`}>
                            {!Lesson.Slides || !Lesson.Slides.length ? <i>
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                            </i> : <></>}
                            Builder
                        </Link>
                    </div>
                </> : <></>}
            </CourseSidebar>

            <div className={styles.PermissionsContent}>
                <BreadCrumb CurrentPage={!ModuleID ? Page === "Details" ? Lesson.Title : Page : "New Lesson"} Links={Module && Course.ID ? Page === "Details" ? [{
                    Text: 'Courses',
                    To: `Admin/Courses`
                }, {
                    Text: `${Course.Title ? Course.Title : "..."}`,
                    To: `Admin/Courses/Details/${EncryptValue(Course.ID.toString())}`
                }, {
                    Text: `Modules`,
                    To: `Admin/Courses/Details/${EncryptValue(Course.ID.toString())}/Modules`
                }, {
                    Text: `${Module.Title ? Module.Title : "..."}`,
                    To: `Admin/Modules/Details/${EncryptValue(Module.ID.toString())}`
                }, {
                    Text: `Lessons`,
                    To: `Admin/Modules/Details/${EncryptValue(Module.ID.toString())}/Lessons`
                }] : [{
                    Text: 'Courses',
                    To: `Admin/Courses`
                }, {
                    Text: `${Course.Title ? Course.Title : "..."}`,
                    To: `Admin/Courses/Details/${EncryptValue(Course.ID.toString())}`
                }, {
                    Text: `Modules`,
                    To: `Admin/Courses/Details/${EncryptValue(Course.ID.toString())}/Modules`
                }, {
                    Text: `${Module.Title ? Module.Title : "..."}`,
                    To: `Admin/Modules/Details/${EncryptValue(Module.ID.toString())}`
                }, {
                    Text: `Lessons`,
                    To: `Admin/Modules/Details/${EncryptValue(Module.ID.toString())}/Lessons`
                }, {
                    Text: `${Lesson.Title ? Lesson.Title : "..."}`,
                    To: `Admin/Lesson/Details/${EncryptValue(Lesson.ID.toString())}`
                }] : []} />
                {children}
            </div>
        </div>
    </Panel>);
};

export default DetailsMaster