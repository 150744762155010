import React, { } from 'react';
import * as styles from './Details.module.scss';
import DetailsMaster from '../Config';
import LessonForm from '../../../Components/LessonForm/LessonForm';
import { Panel } from '../../../Components';
import { DecryptValue } from '../../../../../../Services/Crypto';

const LessonDetails: React.FC = (props: any) => {
    const LessonID: string = props.LessonID ? props.LessonID : "";
    const ModuleID: string | null = new URLSearchParams(location.search).get("ModuleID");

    return (<DetailsMaster LessonID={parseInt(DecryptValue(LessonID))} Page="Details" ModuleID={ModuleID ? parseInt(ModuleID) : undefined}>
        <Panel Title="Details" Buttons={[]}>
            <div className={styles.Section}>
                <LessonForm LessonID={parseInt(DecryptValue(LessonID))} ModuleID={ModuleID ? parseInt(ModuleID) : undefined} />
            </div>
        </Panel>
    </DetailsMaster>);
};

export default LessonDetails