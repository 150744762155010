import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Package } from '../../../Classes/Package';
import { Document as DocProps } from "../../../Classes/Document";
import { API_AdminPackages } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import * as styles from './Documents.module.scss';
import { faCheckDouble, faMinus, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Section } from '../../../Classes/Section';
import { Link } from 'gatsby';

const AdminDocuments: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [sections, setSections] = useState<Section[]>([] as Section[]);
  const [loading, setLoading] = useState<boolean>(true);
  const [packageFilters, setPackageFilters] = useState<number[]>([] as number[]);
  const [sectionFilters, setSectionFilters] = useState<number[]>([] as number[]);
  const [collapseSections, setCollapseSection] = useState<number[]>([] as number[]);
  const [packages, setPackages] = useState<Package[]>([] as Package[]);

  useEffect(() => {
    document.title = "PrimeHR :: Admin";

    Fetch(`${API_AdminPackages}`)
      .then((Response: Package[]) => {
        if (Response) {
          let docs: DocProps[] = [];
          let Sections: Section[] = [];

          setPackages(Response);
          setPackageFilters(Response.map(x => x.ID));

          Response.map(p => {
            p.Documents.map((d: DocProps) => {
              d.Package = JSON.parse(JSON.stringify(p));
              d.Package.Documents = [];
              docs.push(d);
            })
          })

          docs.map(d => {
            if (!Sections.find(s => s.ID === d.SectionID)) {
              d.Section.Documents = docs.filter(x => x.SectionID === d.SectionID);
              Sections.push(d.Section);
            }
          })

          setSectionFilters(Sections.map(x => x.ID));
          setSections(Sections);
          setLoading(false);
        }
      })
  }, [])

  const TogglePackageFilter = (ID: number) => {
    let filters = JSON.parse(JSON.stringify(packageFilters));
    let fil = packageFilters.findIndex(x => x === ID);
    if (fil >= 0) {
      filters.splice(fil, 1);
    } else {
      filters.push(ID);
    }
    setPackageFilters(filters);
  }

  const ToggleSectionFilter = (ID: number) => {
    let filters = JSON.parse(JSON.stringify(sectionFilters));
    let fil = sectionFilters.findIndex(x => x === ID);
    if (fil >= 0) {
      filters.splice(fil, 1);
    } else {
      filters.push(ID);
    }
    setSectionFilters(filters);
  }

  const ToggleCollapseSection = (ID: number) => {
    let sections = JSON.parse(JSON.stringify(collapseSections));
    let coll = collapseSections.findIndex(x => x === ID);
    if (coll >= 0) {
      sections.splice(coll, 1);
    } else {
      sections.push(ID);
    }
    setCollapseSection(sections);
  }

  return (<>
    <div className={styles.Header}>
      <Link to="/Admin/Document/New">Create New</Link>
    </div>
    <div className={styles.Search}>
      <div className={styles.SearchInput}>
        <i><FontAwesomeIcon icon={faSearch} /></i>
        <input type="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
    </div>

    <div className={styles.Documents}>
      <ul className={styles.Filter}>
        <li>
          <p>Package</p>
        </li>
        {
          packages.map(pack => {
            return <li>
              <label>
                <input checked={packageFilters.includes(pack.ID)} type="checkbox" onClick={() => TogglePackageFilter(pack.ID)} />
                <span>{pack.Title} ({pack.Documents.length})</span>
              </label>
            </li>
          })
        }
        <li>
          <p>Category</p>
        </li>
        {
          sections.map(sec => {
            return <li>
              <label>
                <input checked={sectionFilters.includes(sec.ID)} type="checkbox" onClick={() => ToggleSectionFilter(sec.ID)} />
                <span>{sec.Title}</span>
              </label>
            </li>
          })
        }
      </ul>

      <div className={styles.DocumentsInner}>
        {loading ? <div className={styles.Loading}></div> : <>
          <div className={styles.SectionHeader}>
            <button type="button" onClick={() => setCollapseSection([])}>Uncollapse All</button>
            <button type="button" onClick={() => setCollapseSection(sections.map(x => x.ID))}>Collapse All</button>
          </div>
          {sections.filter(x => sectionFilters.includes(x.ID)).map(sec => {
            return <div className={styles.Section}>
              <div className={styles.SectionInner}>
                {sec.Documents.filter(d => d.Title.toLowerCase().includes(search)).length ? <>
                  <h1 onClick={() => ToggleCollapseSection(sec.ID)}><span>{sec.Title} ({sec.Documents.length})</span><i><FontAwesomeIcon icon={collapseSections.includes(sec.ID) ? faPlus : faMinus} /></i></h1>
                  {!collapseSections.includes(sec.ID) ? <ul>
                    {
                      sec.Documents.length ? sec.Documents.map((doc, i) => {
                        if (doc.Title.toLowerCase().includes(search) && packageFilters.find(x => x === doc.PackageID))
                          return <li>
                            <Link to={`/Admin/Document/Edit?id=${doc.ID}`}>
                              <p>
                                <b>{doc.Title}</b>
                                {doc.File ? <i>
                                  <FontAwesomeIcon icon={faCheckDouble} />
                                </i> : <></>}
                                <span>{doc.Tags}</span>
                              </p>
                            </Link>
                          </li>
                      }) : <></>
                    }
                  </ul> : <></>}
                </> : <></>}
              </div>
            </div>
          })}
        </>}
      </div>
    </div>
  </>);
};

export default AdminDocuments;
