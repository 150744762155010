import React, { useEffect } from 'react';
import * as styles from './Details.module.scss';
import DetailsMaster from '../Config';
import ModuleForm from '../../../Components/ModulesForm/ModulesForm';
import { Panel } from '../../../Components';
import { DecryptValue } from '../../../../../../Services/Crypto';

const ModuleDetails: React.FC = (props: any) => {
    const ModuleID: string = props.ModuleID ? props.ModuleID : "";
    const CourseID: string | null = new URLSearchParams(location.search).get("CourseID");

    return (<DetailsMaster ModuleID={parseInt(DecryptValue(ModuleID))} Page="Details" CourseID={CourseID ? parseInt(CourseID) : undefined}>
        <Panel Title="Details" Buttons={[]}>
            <div className={styles.Section}>
                <ModuleForm ModuleID={parseInt(DecryptValue(ModuleID))} CourseID={CourseID ? parseInt(CourseID) : undefined} />
            </div>
        </Panel>
    </DetailsMaster>);
};

export default ModuleDetails