import React, { useContext, useEffect, useState, FormEvent } from 'react';
import * as styles from './ModulesForm.module.scss';
import { API_SaveModule, API_DeleteModule } from '../../ApiRoutes';
import { CourseModule as Module } from '../../Classes/CourseModule';
import { Lesson } from '../../Classes/Lesson';
import { Course } from '../../Classes/Course';
import { navigate } from 'gatsby';
import { OrganisationContext, UserContext } from '../../../../../Context/UserContext';
import { ToastContext } from '../../Context/ToastContext';
import { AlertContext } from '../../Context/ModalContext';
import { Fetch } from '../../../../../Services/Fetch';
import { Button } from '../Button/Button';
import { AlertSettings } from '../Alert/Classes/AlertSettings';
import Input from '../../../../../components/Input/input';
import { EncryptValue } from '../../../../../Services/Crypto';

interface Props {
    ModuleID: number;
    CourseID: number | undefined;
}

const ModuleForm: React.FC<Props> = ({ ModuleID, CourseID }) => {
    const { Organisation, setOrganisation } = useContext(OrganisationContext);
    const { ToastSettings, setToastSettings } = useContext(ToastContext);
    const { setAlert } = useContext(AlertContext);
    const [data, setData] = useState<Module>({
        ID: 0,
        Description: "",
        Title: "",
        Lessons: [] as Lesson[],
        CourseID: CourseID ? CourseID : 0,
        isPublished: false
    } as Module);

    const SaveModule = (Event: FormEvent) => {
        Event.preventDefault();

        if (data.Title) {
            Fetch(`${API_SaveModule}`, data)
                .then((Response: Module) => {
                    if (ModuleID) {
                        setOrganisation({
                            ...Organisation, Courses: Organisation.Courses.map(c => {
                                if (c.ID === data.CourseID)
                                    c.Modules = c.Modules?.map(m => {
                                        if (m.ID === ModuleID)
                                            m = Response;
                                        return m;
                                    })
                                return c;
                            })
                        })
                    } else {
                        let course: Course | undefined = JSON.parse(JSON.stringify(Organisation.Courses.find(c => c.ID === data.CourseID)));
                        if (course) {
                            course.Modules?.push(Response);
                            setOrganisation({
                                ...Organisation, Courses: Organisation.Courses.map(c => {
                                    if (c.ID === data.CourseID)
                                        c.Modules = course?.Modules
                                    return c;
                                })
                            })
                        }
                    }

                    setToastSettings({
                        isOpen: true,
                        message: "Successfully Saved",
                        duration: 2500,
                        onDidDismiss: () => { setToastSettings({ ...ToastSettings, isOpen: false }) }
                    })

                    if (!ModuleID)
                        navigate(`/Admin/Modules/Details/${EncryptValue(Response.ID.toString())}`)
                }).catch(() => {
                })
        }
    }

    const DeleteModule = () => {
        let course: Course | undefined = JSON.parse(JSON.stringify(Organisation.Courses.find(c => c.Modules?.find(m => m.ID === ModuleID))));
        if (course) {
            let mIndex: number | undefined = course.Modules?.findIndex(m => m.ID === ModuleID);

            if (mIndex !== undefined && mIndex >= 0)
                Fetch(`${API_DeleteModule}`, ModuleID)
                    .then((Response) => {
                        if (mIndex !== undefined && mIndex >= 0)
                            course?.Modules?.splice(mIndex, 1);

                        setOrganisation({
                            ...Organisation, Courses: Organisation.Courses.map(c => {
                                if (c.ID === data.CourseID)
                                    c.Modules = course?.Modules
                                return c;
                            })
                        })

                        if (Response) {
                            navigate('/Admin/Modules');
                        }
                    }).catch(() => {
                    })
        }
    }

    const DeleteModuleAlert = () => {
        let alert: AlertSettings = {
            Show: true,
            Icon: "exclamation-circle",
            OnConfirm: () => DeleteModule(),
            Title: "Delete Module",
            SubTitle: "Are you sure you want to PERMENENTLY DELETE this Module?",
        }
        setAlert(alert);
    }

    useEffect(() => {
        if (Organisation.Employees) {
            if (ModuleID) {
                let m: Module | undefined = Organisation.Courses.find(c => c.Modules?.find(m => m.ID === ModuleID))?.Modules?.find(m => m.ID === ModuleID);
                if (m)
                    setData(m);
            }
        }
    }, [Organisation])

    return (Organisation.Employees ? <form className={styles.Form} onSubmit={(e) => SaveModule(e)}>
        <div className={styles.FormDetails}>
            <p>This is the basic information required to create a Module.</p>
            <h1>Instructions:</h1>
            <ol>
                <li>Start by selecting which Course this Module is part of</li>
                <li>Then setting a title for your Lesson</li>
                <li>Then setting a Description</li>
            </ol>
        </div>

        <Input Placeholder="Select Course..." Label="Course" Type="select" Options={
            Organisation.Courses.map((course) => {
                return {
                    Text: course.Title,
                    Value: course.ID.toString()
                }
            })
        } Value={data.CourseID.toString()} OnChange={(v) => { setData({ ...data, CourseID: parseInt(v) }) }} />
        <Input Required Label="Title" Placeholder="..." Type="text" Value={data.Title} OnChange={(v) => { setData({ ...data, Title: v }) }} />
        <Input Label="Description" Placeholder="" Type="textarea" Value={data.Description} OnChange={(v) => { setData({ ...data, Description: v }) }} />
        <Input Label="Is this Module Published?" Placeholder="..." Type="checkbox" Value={data.isPublished} OnChange={(v) => { setData({ ...data, isPublished: v }) }} />

        <div className={styles.Buttons}>
            {!ModuleID ? undefined : <Button Color="Link" OnClick={() => { DeleteModuleAlert() }} Type="button"><p>Delete Module</p></Button>}
            <Button Color="Theme" Type="submit"><p>Save</p></Button>
        </div>
    </form> : <></>);
};

export default ModuleForm