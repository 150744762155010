import React, { useEffect } from 'react';
import * as styles from './Details.module.scss';
import DetailsMaster from '../Config';
import CourseForm from '../../../Components/CourseForm/CourseForm';
import { Panel } from '../../../Components';
import { DecryptValue } from '../../../../../../Services/Crypto';

const CourseDetails: React.FC = (props: any) => {
    const CourseID: string = props.CourseID ? props.CourseID : "";

    return (<DetailsMaster CourseID={parseInt(DecryptValue(CourseID))} Page="Details">
        <Panel Title="Details" Buttons={[]}>
            <div className={styles.Section}>
                <CourseForm CourseID={parseInt(DecryptValue(CourseID))} />
            </div>
        </Panel>
    </DetailsMaster>);
};

export default CourseDetails