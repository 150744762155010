import React, { useEffect, useState, useContext } from 'react';
import DetailsMaster from '../Config';
import * as styles from './Modules.module.scss';
import { CourseModule } from '../../../Classes/CourseModule';
import { Course } from '../../../Classes/Course';
import { Link, navigate } from 'gatsby';
import { OrganisationContext, UserContext } from '../../../../../../Context/UserContext';
import { Panel, Table } from '../../../Components';
import { DecryptValue, EncryptValue } from '../../../../../../Services/Crypto';

const CourseModules: React.FC = (props: any) => {
  const CourseID: string = props.CourseID ? props.CourseID : "";

  const { Organisation } = useContext(OrganisationContext);
  const { User } = useContext(UserContext);
  const [Modules, setModules] = useState<CourseModule[]>([] as CourseModule[]);

  const GetIcon = () => {
    let items: JSX.Element[] = [];

    Modules.map((module) => {
      items.push(<>
        <Link to={`/Admin/Modules/Details/${EncryptValue(module.ID.toString())}`} className={styles.Link}>
          {module.Title}
        </Link>
        {module.Description ? <span>{module.Description}</span> : <></>}
      </>)
    })

    return items
  }

  const GetLessons = () => {
    let items: JSX.Element[] = [];

    Modules.map((module) => {
      items.push(<div className={styles.Status}>
        <Link to={`/Admin/Modules/Details/${EncryptValue(module.ID.toString())}/Lessons`}>
          {module.Lessons.length} Lessons
        </Link>
      </div>)
    })

    return items
  }

  const GetModules = () => {
    let course: Course | undefined = Organisation.Courses.find(c => c.ID === parseInt(DecryptValue(CourseID)));
    if (course && course.Modules)
      setModules(course.Modules);
  }

  useEffect(() => {
    if (User.ID)
      GetModules();
  }, [User])

  return (<DetailsMaster CourseID={parseInt(DecryptValue(CourseID))} Page="Modules">
    <Panel Footer={false} Title="Modules" Buttons={[{
      Color: "Theme",
      Children: <p>Add Module</p>,
      OnClick: () => { navigate(`/Admin/Modules/Details/${EncryptValue("0")}?CourseID=${parseInt(DecryptValue(CourseID))}`) }
    }]}>
      <Table ShowPagination={true} Data={Modules} DataHead={[
        {
          Text: "Title",
          Key: "",
          DisplayAs: "custom",
          CustomItem: GetIcon(),
        }, {
          Text: "Lessons",
          Key: "",
          DisplayAs: "custom",
          Align: "center",
          CustomItem: GetLessons(),
          CustomWidth: 150
        }]} />
    </Panel>
  </DetailsMaster>);
};

export default CourseModules;
