import React, { useEffect, useState } from 'react';
import { Package } from '../../../../Classes/Package';
import { API_AdminPackages, API_SavePackage } from '../../../../Services/ApiRoutes';
import { Fetch } from '../../../../Services/Fetch';
import * as styles from './PackageEdit.module.scss';
import { Loading } from '../../../../components/Loading/Loading';

type ErrorMessage = {
  Message: string;
  Class: string;
}

interface Props {
  id: string;
}

const AdminEditPackage: React.FC<Props> = ({ id }) => {
  const [packageData, setPackage] = useState<Package>({} as Package);
  const [packageForm, setPackageForm] = useState<Package>({} as Package);
  const [message, setMessage] = useState<ErrorMessage>({} as ErrorMessage);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      setLoading(true);

      Fetch(`${API_AdminPackages}`)
        .then((Response: Package[]) => {
          if (Response) {
            let packData: Package | undefined = Response.find(p => p.ID === parseInt(id));
            if (packData) {
              setPackage(packData);
              setPackageForm(packData);
              setLoading(false);
            }
          }
        })
    }
  }, [id])

  const Save = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    await Fetch(`${API_SavePackage}`, JSON.parse(JSON.stringify(packageForm))).then((res: Package) => {
      setPackage(res);
      setPackageForm(res);
      setMessage({
        Class: "Success",
        Message: "Successfully updated the Package"
      } as ErrorMessage);
    })
    setLoading(false);
  }

  return (<>
    {loading ? <div className={styles.Loading}><Loading /></div> : <form className={styles.Form} onSubmit={(e) => Save(e)}>
      <div className={`${styles.Input}`}>
        <label>Title *</label>
        <input type="text" value={packageForm.Title} onChange={(e) => setPackageForm({ ...packageForm, Title: e.target.value })} required />
      </div>

      <div className={`${styles.Checkbox} ${styles.Half}`}>
        <input type="checkbox" checked={packageForm.isDefault} onChange={(e) => setPackageForm({ ...packageForm, isDefault: e.target.checked })} />
        <label>Is assigned on sign up</label>
      </div>

      <div className={`${styles.Checkbox} ${styles.Half}`}>
        <input type="checkbox" checked={packageForm.isFree} onChange={(e) => setPackageForm({ ...packageForm, isFree: e.target.checked })} />
        <label>Free Package</label>
      </div>

      {!packageForm.isFree ? <>
        <div className={`${styles.Input} ${styles.Half}`}>
          <label>1 Month Cost *</label>
          <input type="text" value={packageForm.Cost} onChange={(e) => setPackageForm({ ...packageForm, Cost: e.target.value })} />
        </div>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>3 Month Cost *</label>
          <input type="text" value={packageForm.Cost3} onChange={(e) => setPackageForm({ ...packageForm, Cost3: e.target.value })} />
        </div>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>6 Month Cost *</label>
          <input type="text" value={packageForm.Cost6} onChange={(e) => setPackageForm({ ...packageForm, Cost6: e.target.value })} />
        </div>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>12 Month Cost *</label>
          <input type="text" value={packageForm.Cost12} onChange={(e) => setPackageForm({ ...packageForm, Cost12: e.target.value })} />
        </div>
      </> : <></>}

      <div className={`${styles.Input}`}>
        <label>Description</label>
        <textarea value={packageForm.Description} onChange={(e) => setPackageForm({ ...packageForm, Description: e.target.value })}></textarea>
      </div>

      {
        message.Message ? <div className={`${styles.Message} ${styles[message.Class]}`}>
          <p>{message.Message}</p>
        </div> : <></>
      }

      <button type="submit">
        Save
      </button>
    </form>}
  </>);
};

export default AdminEditPackage;
