import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { AdviceLine } from '../../../Classes/AdviceLine';
import { User } from '../../../Classes/User';
import { Loading } from '../../../components/Loading/Loading';
import { API_AdviceLines, API_GetAccounts } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import * as styles from '../AdminBase.module.scss';

const AdminAccounts: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [accounts, setAccounts] = useState<User[]>([] as User[]);
  const [adviceLines, setAdviceLines] = useState<AdviceLine[]>([] as AdviceLine[]);

  useEffect(() => {
    document.title = "PrimeHR :: Admin";
    GetData();
  }, [])

  const GetData = async () => {
    setLoading(true);

    await Fetch(`${API_GetAccounts}`)
      .then((Response: User[]) => {
        if (Response) {
          setAccounts(Response);
        }
      })

    await Fetch(`${API_AdviceLines}`)
      .then((Response: AdviceLine[]) => {
        if (Response) {
          setAdviceLines(Response);
        }
      })

    setLoading(false);
  }

  return (<>
    <div className={styles.Search}>
      <div className={styles.SearchInput}>
        <i><FontAwesomeIcon icon={faSearch} /></i>
        <input type="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
    </div>

    <div className={styles.Table}>
      <div className={styles.Header}>
        <div className={styles.Cell}>
          <p>Name</p>
        </div>
        <div className={styles.Cell}>
          <p>Company</p>
        </div>
        <div className={styles.Cell}>
          <p>Phone</p>
        </div>
        <div className={styles.Cell}>
          <p>Email</p>
        </div>
        <div className={styles.Cell}>
          <p>Package</p>
        </div>
        <div className={styles.Cell}>
          <p>Employees</p>
        </div>
        <div className={styles.Cell}>
          <p>Advice Line</p>
        </div>
      </div>
      {loading ? <Loading /> : <div className={styles.Body}>
        {
          accounts.filter(a => `${a.Title}. ${a.Forename} ${a.Surname}`.toLowerCase().includes(search.toLowerCase())).map(acc => {
            return <Link to={`/Admin/Accounts/Edit/${acc.ID}`} className={styles.Row}>
              <div className={styles.Cell}>
                <p>{acc.Title}. {acc.Forename} {acc.Surname}</p>
              </div>
              <div className={styles.Cell}>
                <p>{acc.Company}</p>
              </div>
              <div className={styles.Cell}>
                <p>{acc.Phone}</p>
              </div>
              <div className={styles.Cell}>
                <p>{acc.Email}</p>
              </div>
              <div className={styles.Cell}>
                <p>{acc.Subscription ? acc.PackageID === 5 ? "Custom" : acc.PackageID === 3 ? "Gold" : acc.PackageID === 2 ? "Silver" : "Bronze" : "Bronze"}</p>
              </div>
              <div className={styles.Cell}>
                <p>{adviceLines.find(a => a.ID === parseInt(acc.Employees))?.Title}</p>
              </div>
              <div className={styles.Cell}>
                <p>{acc.Subscription && acc.Subscription.hasAdviceLine ? adviceLines.find(a => a.ID === parseInt(acc.Subscription.AdviceID))?.Title : ""}</p>
              </div>
            </Link>
          })
        }
      </div>}
    </div>
  </>);
};

export default AdminAccounts;
