import React, { useEffect, useState, useContext } from 'react';
import * as styles from './Module.module.scss';
import { CourseModule as Module, CourseModule } from '../../Classes/CourseModule';
import { Course } from '../../Classes/Course';
import CourseSidebar from '../../Components/Sidebar/Sidebar';
import { Link, navigate } from 'gatsby';
import { OrganisationContext, UserContext } from '../../../../../Context/UserContext';
import { BreadCrumb, Panel } from '../../Components';
import { EncryptValue } from '../../../../../Services/Crypto';

interface Props {
    ModuleID: number;
    Page: "Details" | "Lessons" | "Workflow";
    CourseID?: number;
    children?: any;
}

const DetailsMaster: React.FC<Props> = ({ children, ModuleID, Page, CourseID }) => {
    const { Organisation } = useContext(OrganisationContext);
    const [module, setModule] = useState<Module>({} as Module);
    const [course, setCourse] = useState<Course>();

    const GetCourse = () => {
        if (ModuleID) {
            let thisCourse: Course | undefined = Organisation.Courses.find(c => c.Modules?.find(m => m.ID === ModuleID));
            if (thisCourse) {
                let thisModule: CourseModule | undefined = thisCourse.Modules?.find(m => m.ID === ModuleID);
                setCourse(thisCourse);
                if (thisModule)
                    setModule(thisModule);
            } else
                navigate(`/Portal/`)
        } else
            if (CourseID) {
                let thisCourse: Course | undefined = Organisation.Courses.find(c => c.ID === CourseID);

                if (thisCourse)
                    setCourse(thisCourse)
                else
                    navigate(`/Portal/Courses`)
            } else {
                navigate(`/Portal/Courses`)
            }
    }

    useEffect(() => {
        if (Organisation.Employees)
            GetCourse()
    }, [ModuleID, CourseID, Organisation])

    return (<Panel Footer={false} Title="Module Settings" Width="100%" Buttons={[]} Padding="0px">
        <div className={styles.Permissions}>
            <CourseSidebar>
                <div className={styles.SidebarLink}>
                    <Link className={`${Page === "Details" ? styles.Active : ""} ${CourseID ? styles.Disabled : ""}`} to={`/Admin/Modules/Details/${EncryptValue(ModuleID.toString())}`}>
                        Details
                    </Link>
                </div>

                {ModuleID ? <>
                    <div className={styles.SidebarLink}>
                        <Link className={Page === "Lessons" ? styles.Active : ""} to={`/Admin/Modules/Details/${EncryptValue(ModuleID.toString())}/Lessons`}>
                            Lessons
                        </Link>
                    </div>

                    <div className={styles.SidebarLink}>
                        <Link className={Page === "Workflow" ? styles.Active : ""} to={`/Admin/Modules/Details/${EncryptValue(ModuleID.toString())}/Workflow`}>
                            Workflow
                        </Link>
                    </div>
                </> : <></>}
            </CourseSidebar>

            <div className={styles.PermissionsContent}>
                <BreadCrumb CurrentPage={!CourseID ? Page === "Details" ? module.Title : Page : "New Module"} Links={course ? Page === "Details" ? [{
                    Text: 'Courses',
                    To: `Admin/Courses`
                }, {
                    Text: `${course.Title}`,
                    To: `Admin/Courses/Details/${EncryptValue(course.ID.toString())}`
                }, {
                    Text: `Modules`,
                    To: `Admin/Courses/Details/${EncryptValue(course.ID.toString())}/Modules`
                }] : [{
                    Text: 'Courses',
                    To: `Admin/Courses`
                }, {
                    Text: `${course.Title}`,
                    To: `Admin/Courses/Details/${EncryptValue(course.ID.toString())}`
                }, {
                    Text: `Modules`,
                    To: `Admin/Courses/Details/${EncryptValue(course.ID.toString())}/Modules`
                }, {
                    Text: `${module.Title}`,
                    To: `Admin/Modules/Details/${EncryptValue(module.ID.toString())}`
                }] : []} />
                {children}
            </div>
        </div>
    </Panel>);
};

export default DetailsMaster