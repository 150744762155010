// extracted by mini-css-extract-plugin
export var Active = "Builder-module--Active--92c59";
export var Body = "Builder-module--Body--76b5c";
export var BodyContent = "Builder-module--BodyContent--6cf70";
export var BodyInner = "Builder-module--BodyInner--b1fa4";
export var Button = "Builder-module--Button--4b5a4";
export var Buttons = "Builder-module--Buttons--d5385";
export var Content = "Builder-module--Content--5bc67";
export var Control = "Builder-module--Control--a8b82";
export var Header = "Builder-module--Header--6c45f";
export var HeaderText = "Builder-module--HeaderText--d3379";
export var Item = "Builder-module--Item--09b49";
export var Left = "Builder-module--Left--e87e4";
export var Lesson = "Builder-module--Lesson--0c76c";
export var LessonOverlay = "Builder-module--LessonOverlay--1cf19";
export var Main = "Builder-module--Main--b019d";
export var Nav = "Builder-module--Nav--d2bdf";
export var NavItem = "Builder-module--NavItem--ac58a";
export var NavItemInput = "Builder-module--NavItemInput--42c66";
export var Progress = "Builder-module--Progress--b4616";
export var ProgressInner = "Builder-module--ProgressInner--e3df3";
export var Right = "Builder-module--Right--5117b";
export var Show = "Builder-module--Show--eb43b";
export var Sidebar = "Builder-module--Sidebar--4b021";
export var Slide = "Builder-module--Slide--e9257";
export var SlideOverlay = "Builder-module--SlideOverlay--256d4";
export var Slides = "Builder-module--Slides--27d72";
export var SlidesControls = "Builder-module--SlidesControls--16ae1";
export var SlidesInner = "Builder-module--SlidesInner--74793";
export var deleted = "Builder-module--deleted--4689e";