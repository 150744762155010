import React, { } from 'react';
import * as styles from './FileUpload.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

interface Props {
    OnChange: (Value: string) => void;
    Shape?: "Circle";
}

export const FileUpload: React.FC<Props> = ({ OnChange, Shape }) => {
    const getBase64 = (file: Blob, cb: (arg0: string | ArrayBuffer | null) => void) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const ReadFile = (Event: React.ChangeEvent<HTMLInputElement>) => {
        let file = Event.target.files ? Event.target.files[0] : undefined;
        if (file) {
            getBase64(file, (result) => {
                if (result)
                    OnChange(result?.toString())
            });
        }
    }

    return (<div className={`${styles.FileUpload} ${Shape ? styles[Shape] : ""}`}>
        <i>
            <FontAwesomeIcon icon={faCloudUploadAlt} />
        </i>
        <input type="file" onChange={(e) => { ReadFile(e) }} />
    </div>);
};
