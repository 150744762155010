import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { AdviceLine } from '../../../Classes/AdviceLine';
import { Loading } from '../../../components/Loading/Loading';
import { API_AdviceLines } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import * as styles from '../AdminBase.module.scss';

const AdminAdviceLines: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [adviceLines, setAdviceLines] = useState<AdviceLine[]>([] as AdviceLine[]);

  useEffect(() => {
    document.title = "PrimeHR :: Admin";

    Fetch(`${API_AdviceLines}`)
      .then((Response: AdviceLine[]) => {
        if (Response) {
          setAdviceLines(Response);
          setLoading(false);
        }
      })
  }, [])

  return (<>
    <div className={styles.Search}>
      <div className={styles.SearchInput}>
        <i><FontAwesomeIcon icon={faSearch} /></i>
        <input type="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
    </div>

    <div className={styles.Table}>
      <div className={styles.Header}>
        <div className={styles.Cell}>
          <p>Title</p>
        </div>
        <div className={styles.Cell}>
          <p>1 Month Cost</p>
        </div>
        <div className={styles.Cell}>
          <p>12 Month Cost</p>
        </div>
      </div>
      {loading ? <Loading /> : <div className={styles.Body}>
        {
          adviceLines.filter(a => a.Title.toLowerCase().includes(search.toLowerCase())).map(adv => {
            return <Link to={`/Admin/AdviceLine/Edit?id=${adv.ID}`} className={styles.Row}>
              <div className={styles.Cell}>
                <p>{adv.Title}</p>
              </div>
              <div className={styles.Cell}>
                <p>{adv.MonthPrice}</p>
              </div>
              <div className={styles.Cell}>
                <p>{adv.YearPrice}</p>
              </div>
            </Link>
          })
        }
      </div>}
    </div>
  </>);
};

export default AdminAdviceLines;
