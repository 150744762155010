import React, { useContext, useEffect, useState, FormEvent } from 'react';
import * as styles from './LessonForm.module.scss';
import { API_SaveLesson, API_DeleteLesson } from '../../ApiRoutes';
import { CourseModule as Module, CourseModule } from '../../Classes/CourseModule';
import { Lesson } from '../../Classes/Lesson';
import { Slide } from '../../Classes/Slide';
import { Course } from '../../Classes/Course';
import { navigate } from 'gatsby';
import { ToastContext } from '../../Context/ToastContext';
import { AlertContext } from '../../Context/ModalContext';
import { OrganisationContext } from '../../../../../Context/UserContext';
import { Fetch } from '../../../../../Services/Fetch';
import { Button } from '../Button/Button';
import { AlertSettings } from '../Alert/Classes/AlertSettings';
import Input from '../../../../../components/Input/input';
import { EncryptValue } from '../../../../../Services/Crypto';

interface Props {
    LessonID: number;
    ModuleID: number | undefined;
}

const LessonForm: React.FC<Props> = ({ LessonID, ModuleID }) => {
    const { ToastSettings, setToastSettings } = useContext(ToastContext);
    const { AlertSettings, setAlert } = useContext(AlertContext);
    const { Organisation, setOrganisation } = useContext(OrganisationContext);
    const [Modules, setModules] = useState<Module[]>([] as Module[]);

    const [data, setData] = useState<Lesson>({
        ID: 0,
        isLocked: false,
        ModuleID: ModuleID ? ModuleID : 0,
        Slides: [] as Slide[],
        Title: '',
        Next: 0,
        Previous: 0,
        isPublished: false
    } as Lesson);

    const GetModule = () => {
        if (ModuleID) {
            let course: Course | undefined = Organisation.Courses.find(c => c.Modules?.find(m => m.ID === ModuleID));

            if (course) {
                setModules(course.Modules ? course.Modules : [] as CourseModule[])
            }
        }
    }

    const GetLesson = () => {
        let course: Course | undefined = Organisation.Courses.find(c => c.Modules?.find(m => m.Lessons.find(l => l.ID === LessonID)));
        if (course) {
            let module: CourseModule | undefined = course.Modules?.find(m => m.Lessons.find(l => l.ID === LessonID));
            setModules(course.Modules ? course.Modules : [] as CourseModule[]);

            if (module) {
                let lesson: Lesson | undefined = module.Lessons.find(l => l.ID === LessonID);
                if (lesson)
                    setData(lesson);
            }
        }
    }

    const SaveLesson = (Event: FormEvent) => {
        Event.preventDefault();

        if (data.Title) {
            let course: Course | undefined = Organisation.Courses.find(c => c.Modules?.find(m => m.ID === data.ModuleID));
            if (course) {
                let module: CourseModule | undefined = course.Modules?.find(m => m.ID === data.ModuleID);
                if (module) {
                    Fetch(`${API_SaveLesson}`, data)
                        .then((Response: Lesson) => {
                            if (LessonID) {
                                setOrganisation({
                                    ...Organisation, Courses: Organisation.Courses.map(c => {
                                        if (c.ID === course?.ID)
                                            c.Modules = c.Modules?.map(m => {
                                                if (m.ID === module?.ID)
                                                    m.Lessons = m.Lessons.map(l => {
                                                        if (l.ID === LessonID)
                                                            l = Response;
                                                        return l;
                                                    })
                                                return m;
                                            })
                                        return c;
                                    })
                                })
                            } else {
                                module?.Lessons.push(Response);
                                setOrganisation({
                                    ...Organisation, Courses: Organisation.Courses.map(c => {
                                        if (c.ID === course?.ID)
                                            c.Modules = c.Modules?.map(m => {
                                                if (m.ID === module?.ID)
                                                    m.Lessons = module.Lessons;
                                                return m;
                                            })
                                        return c;
                                    })
                                })
                            }

                            setToastSettings({
                                isOpen: true,
                                message: "Successfully Saved",
                                duration: 2500,
                                onDidDismiss: () => { setToastSettings({ ...ToastSettings, isOpen: false }) }
                            })
                            if (!LessonID)
                                navigate(`/Admin/Lesson/Details/${EncryptValue(Response.ID.toString())}`)
                        }).catch(() => {
                        })
                }
            }
        }
    }

    const DeleteLesson = () => {
        let course: Course | undefined = Organisation.Courses.find(c => c.Modules?.find(m => m.Lessons.find(l => l.ID === LessonID)));
        if (course) {
            let module: CourseModule | undefined = course.Modules?.find(m => m.Lessons.find(l => l.ID === LessonID));
            if (module) {
                let lIndex: number | undefined = module?.Lessons.findIndex(l => l.ID === LessonID);

                if (lIndex !== undefined && lIndex >= 0)
                    Fetch(`${API_DeleteLesson}`, LessonID)
                        .then((Response) => {
                            if (lIndex !== undefined && lIndex >= 0)
                                module?.Lessons.splice(lIndex, 1);

                            setOrganisation({
                                ...Organisation, Courses: Organisation.Courses.map(c => {
                                    if (c.ID === course?.ID)
                                        c.Modules = c.Modules?.map(m => {
                                            if (m.ID === module?.ID)
                                                m.Lessons = module.Lessons
                                            return m;
                                        })
                                    return c;
                                })
                            })

                            if (Response) {
                                navigate('/Admin/Courses');
                            }
                        }).catch(() => {
                        })
            }
        }
    }

    const DeleteLessonAlert = () => {
        let alert: AlertSettings = {} as AlertSettings;
        if (data.Next || data.Previous) {
            alert = {
                Show: true,
                Icon: "exclamation-circle",
                OnConfirm: () => setAlert({ ...AlertSettings, Show: false }),
                Title: "Currently Active",
                SubTitle: "You first need to remove this Lesson from the Module Workflow",
            }
        } else {
            alert = {
                Show: true,
                Icon: "exclamation-circle",
                OnConfirm: () => DeleteLesson(),
                Title: "Delete Lesson",
                SubTitle: "Are you sure you want to PERMENENTLY DELETE this Lesson?",
            }
        }
        setAlert(alert);
    }

    useEffect(() => {
        if (Organisation.Employees) {
            if (LessonID)
                GetLesson();
            if (ModuleID)
                GetModule()
        }
    }, [Organisation, LessonID])

    return (<form className={styles.Form} onSubmit={(e) => SaveLesson(e)}>
        <div className={styles.FormDetails}>
            <p>This is the basic information required to create a Lesson.</p>
            <h1>Instructions:</h1>
            <ol>
                <li>Start by selecting which Module this Lesson is part of</li>
                <li>Then setting a title for your Lesson</li>
            </ol>
        </div>

        <Input Placeholder="Select Module..." Label="Module *" Type="select" Options={
            Modules.map((module) => {
                return {
                    Text: module.Title,
                    Value: module.ID.toString()
                }
            })
        } Value={data.ModuleID.toString()} OnChange={(v) => { setData({ ...data, ModuleID: parseInt(v) }) }} />

        <Input Label="Title *" Placeholder="..." Type="text" Value={data.Title} OnChange={(v) => { setData({ ...data, Title: v }) }} />
        <Input Label="Is this Lesson Published?" Placeholder="..." Type="checkbox" Value={data.isPublished} OnChange={(v) => { setData({ ...data, isPublished: v }) }} />

        <div className={styles.Buttons}>
            {!LessonID ? undefined : <Button Color="Link" OnClick={() => { DeleteLessonAlert() }} Type="button">
                <p>Delete Lesson</p>
            </Button>}
            <Button Color="Theme" Type="submit">
                <p>Save</p>
            </Button>
        </div>
    </form>);
};

export default LessonForm