import React, { useEffect, useState } from 'react';
import { Document, Parameter } from '../../../../Classes/Document';
import { Package } from '../../../../Classes/Package';
import { Section } from '../../../../Classes/Section';
import { API_AdminPackages, API_SaveDocument } from '../../../../Services/ApiRoutes';
import { Fetch } from '../../../../Services/Fetch';
import * as styles from './DocumentEdit.module.scss';
import { Plugins } from '@capacitor/core';
import { Loading } from '../../../../components/Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { DecryptValue } from '../../../../Services/Crypto';
const { Storage } = Plugins;

type ErrorMessage = {
  Message: string;
  Class: string;
}

interface Props {
  id: string;
}

const AdminEditDocument: React.FC<Props> = ({ id }) => {
  const [documentData, setDocument] = useState<Document>({} as Document);
  const [documentForm, setDocumentForm] = useState<Document>({} as Document);
  const [sections, setSections] = useState<Section[]>([] as Section[]);
  const [packages, setPackages] = useState<Package[]>([] as Package[]);
  const [message, setMessage] = useState<ErrorMessage>({} as ErrorMessage);
  const [loading, setLoading] = useState<boolean>(true);
  const [parameters, setParameters] = useState<Parameter[]>([] as Parameter[]);

  useEffect(() => {
    if (id) {
      setLoading(true);

      Fetch(`${API_AdminPackages}`)
        .then((Response: Package[]) => {
          if (Response) {
            let docData: Document | undefined = Response.find(p => p.Documents.find(d => d.ID === parseInt(id)))?.Documents.find(d => d.ID === parseInt(id));
            if (docData) {
              setDocument(docData);
              setDocumentForm(docData);
              setParameters(docData.Parameters);
              setPackages(Response);

              let docs: Document[] = [];
              let Sections: Section[] = [];

              Response.map(p => {
                p.Documents.map((d: Document) => {
                  d.Package = JSON.parse(JSON.stringify(p));
                  d.Package.Documents = [];
                  docs.push(d);
                })
              })

              docs.map(d => {
                if (!Sections.find(s => s.ID === d.SectionID)) {
                  d.Section.Documents = docs.filter(x => x.SectionID === d.SectionID);
                  Sections.push(d.Section);
                }
              })

              setSections(Sections);
              setLoading(false);
            }
          }
        })
    }
  }, [id])

  const Save = (e: any) => {
    setLoading(true);
    e.preventDefault();
    var input: any = document.querySelector('input[type="file"]');
    var data = new FormData()
    data.append('file', input.files[0])
    data.append('Title', documentForm.Title);
    data.append('Icon', documentForm.Icon);
    data.append('Description', documentForm.Description);
    data.append('PackageID', documentForm.PackageID.toString());
    data.append('SectionID', documentForm.SectionID.toString());
    data.append('ID', documentForm.ID.toString());
    data.append('Tags', documentForm.Tags);

    let Token: string | null = localStorage.getItem("User");
    if (Token) {
      fetch(`${API_SaveDocument}`, {
        method: 'POST',
        headers: {
          'Authorization': "Bearer " + DecryptValue(Token)
        },
        body: data
      }).then((Response) => {
        if (Response.ok)
          return Response.json();
        throw Response;
      }).then((Response: string) => {
        setDocumentForm({ ...documentForm, FileTitle: Response });
        setMessage({
          Class: "Success",
          Message: "Successfully updated the Document"
        } as ErrorMessage);
        setLoading(false);
      });
    }
  }

  return (<>
    {loading ? <div className={styles.Loading}><Loading /></div> : <>
      <form className={styles.Form} onSubmit={(e) => Save(e)}>
        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Title *</label>
          <input type="text" value={documentForm.Title} onChange={(e) => setDocumentForm({ ...documentForm, Title: e.target.value })} required />
        </div>
        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Icon *</label>
          <select value={documentForm.Icon} onChange={(e) => setDocumentForm({ ...documentForm, Icon: e.target.value })}>
            <option value="">Select Icon...</option>
            <option value="fas fa-file-word">Word</option>
            <option value="fas fa-file-powerpoint">Powerpoint</option>
            <option value="fas fa-file-pdf">PDF</option>
            <option value="fas fa-file-image">Image</option>
            <option value="fas fa-file-excel">Excel</option>
            <option value="fas fa-file-csv">CSV</option>
            <option value="fas fa-file-code">Code</option>
            <option value="fas fa-file-invoice">Invoice</option>
            <option value="fas fa-file-contract">Contract</option>
            <option value="fas fa-file-archive">Compressed</option>
            <option value="fas fa-file-medical">Medical</option>
            <option value="fas fa-file-alt">Text</option>
            <option value="fas fa-file-video">Video</option>
            <option value="fas fa-file-audio">Audio</option>
            <option value="fas fa-file">None Of The Above</option>
          </select>
        </div>
        <div className={`${styles.Input}`}>
          <label>Description *</label>
          <textarea value={documentForm.Description} onChange={(e) => setDocumentForm({ ...documentForm, Description: e.target.value })} required></textarea>
        </div>
        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Package *</label>
          <select value={documentForm.PackageID} onChange={(e) => setDocumentForm({ ...documentForm, PackageID: parseInt(e.target.value) })}>
            <option value="">Select Package...</option>
            {
              packages.map(pack => {
                return <option value={pack.ID}>{pack.Title}</option>
              })
            }
          </select>
        </div>
        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Section *</label>
          <select value={documentForm.SectionID} onChange={(e) => setDocumentForm({ ...documentForm, SectionID: parseInt(e.target.value) })}>
            <option value="">Select Section...</option>
            {
              sections.map(sec => {
                return <option value={sec.ID}>{sec.Title}</option>
              })
            }
          </select>
        </div>
        <div className={`${styles.Input}`}>
          <label>Document *</label>
          <input type="file" />
          <span>{documentData.FileTitle}</span>
        </div>

        <div className={`${styles.Input}`}>
          <label>Tags (Seperate With Comma) *</label>
          <input type="text" value={documentForm.Tags} onChange={(e) => setDocumentForm({ ...documentForm, Tags: e.target.value })} />
        </div>

        {
          message.Message ? <div className={`${styles.Message} ${styles[message.Class]}`}>
            <p>{message.Message}</p>
          </div> : <></>
        }

        <button className={styles.Button} type="submit">
          Save
        </button>
      </form>

      <div className={styles.Parameters}>
        <div className={styles.ParametersInner}>
          {
            parameters.map((param, ind) => {
              return <div className={styles.Param}>
                <div className={`${styles.Input}`}>
                  {(ind > 1) ? <></> : <label>Parameter</label>}
                  <i>
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  </i>
                  <input type="text" value={param.Label} readOnly={true} />
                  <i>
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                  </i>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </>}
  </>);
};

export default AdminEditDocument;
