import React, { useEffect, useState } from 'react';
import { Fetch } from '../../../Services/Fetch';
import * as styles from '../AdminBase.module.scss';
import { API_AdminRefunds } from '../../../Services/ApiRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../../../components/Loading/Loading';
import { Refund } from '../../../Classes/Refund';
import { Link } from 'gatsby';

const AdminRefunds: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [refunds, setRefunds] = useState<Refund[]>([] as Refund[]);

  useEffect(() => {
    document.title = "PrimeHR :: Admin";

    GetData();
  }, [])

  const GetData = async () => {
    await Fetch(`${API_AdminRefunds}`)
      .then((Response: Refund[]) => {
        if (Response) {
          setRefunds(Response);
        }
      })

    setLoading(false);
  }

  return (<>
    <div className={styles.Search}>
      <div className={styles.SearchInput}>
        <i><FontAwesomeIcon icon={faSearch} /></i>
        <input type="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
    </div>

    <div className={styles.Table}>
      <div className={styles.Header}>
        <div className={styles.Cell}>
          <p>Amount</p>
        </div>
        <div className={styles.Cell}>
          <p>Reference</p>
        </div>
        <div className={styles.Cell}>
          <p>Request Date</p>
        </div>
        <div className={styles.Cell}>
          <p>Refund By</p>
        </div>
        <div className={styles.Cell}>
          <p>Status</p>
        </div>
      </div>
      {loading ? <Loading /> : <div className={styles.Body}>
        {
          refunds.filter(s => s.Reference.toLowerCase().includes(search.toLowerCase())).map(ref => {
            return <Link to={`/Admin/Refunds/Edit?id=${ref.ID}`} className={styles.Row}>
              <div className={styles.Cell}>
                <p>£{parseInt(ref.Amount) / 100}</p>
              </div>
              <div className={styles.Cell}>
                <p>{ref.Reference}</p>
              </div>
              <div className={styles.Cell}>
                <p>{new Date(ref.RequestDate).toLocaleString()}</p>
              </div>
              <div className={styles.Cell}>
                <p>{new Date(ref.RefundBy).toLocaleString()}</p>
              </div>
              <div className={styles.Cell}>
                <p>{ref.Status}</p>
              </div>
            </Link>
          })
        }
      </div>}
    </div>
  </>);
};

export default AdminRefunds;
