import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/layout';
import AdminNav from '../../components/AdminNav/AdminNav';
import AdminDocuments from '../../Views/Admin/Documents/Documents';
import AdminContacts from '../../Views/Admin/Contacts/Contacts';
import AdminRefunds from '../../Views/Admin/Refunds/Refunds';
import AdminEditRefund from '../../Views/Admin/Refunds/Edit/RefundEdit';
import AdminEditDocument from '../../Views/Admin/Documents/Edit/DocumentEdit';
import AdminNewDocument from '../../Views/Admin/Documents/New/DocumentNew';
import AdminAccounts from '../../Views/Admin/Accounts/Accounts';
import AdminEditPackage from '../../Views/Admin/Packages/Edit/PackageEdit';
import AdminPackages from '../../Views/Admin/Packages/Packages';
import AdminEditAdviceLine from '../../Views/Admin/AdviceLines/Edit/AdviceLineEdit';
import AdminAdviceLines from '../../Views/Admin/AdviceLines/AdviceLines';
import AdminEditSection from '../../Views/Admin/Sections/Edit/SectionEdit';
import AdminSections from '../../Views/Admin/Sections/Sections';
import AdminNewReport from '../../Views/Admin/Reports/New/ReportNew';
import AdminEditReport from '../../Views/Admin/Reports/Edit/ReportEdit';
import { Router } from "@reach/router";
import AdminEditAccount from '../../Views/Admin/Accounts/Edit/AccountEdit';
import Courses from '../../Views/Portal/Courses/Courses/Table/Courses';
import CourseDetails from '../../Views/Portal/Courses/Courses/Details/Details/Details';
import CourseModules from '../../Views/Portal/Courses/Courses/Details/Modules/Modules';
import Modules from '../../Views/Portal/Courses/Modules/Table/Modules';
import ModuleDetails from '../../Views/Portal/Courses/Modules/Details/Details/Details';
import ModuleLessons from '../../Views/Portal/Courses/Modules/Details/Lessons/Lessons';
import ModuleWorkflow from '../../Views/Portal/Courses/Modules/Details/Workflow/Workflow';
import Lessons from '../../Views/Portal/Courses/Lessons/Table/Lessons';
import LessonDetails from '../../Views/Portal/Courses/Lessons/Details/Details/Details';
import LessonBuilder from '../../Views/Portal/Courses/Lessons/Details/Builder/Builder';
import Lesson from '../../Views/Portal/Courses/Lessons/Lesson/Lesson';
import { Package } from '../../Classes/Package';

const AdminPortalPage: React.FC = (props: any) => {
  const Packages: Package[] = props.pageContext.Packages;

  const [id, setID] = useState("");

  useEffect(() => {
    setID("");

    if (props.location.search.toLowerCase().replace("?", "").split("&").find(p => p.split("=")[0] === "id"))
      props.location.search.toLowerCase().replace("?", "").split("&").map(p => {
        var value = p.split("=")[0];

        if (value === "id")
          setID(p.split("=")[1]);
      })
    else
      setID(undefined);
  }, [props])

  return (
    <Layout>
      <AdminNav PackageData={Packages}>
        <Router basepath="/Admin" className={"router"}>
          <PrivateRoute path="/" component={AdminDocuments} />

          <PrivateRoute path="/Contacts" component={AdminContacts} />

          <PrivateRoute path="/Report/New" component={() => <AdminNewReport id={id} />} />
          <PrivateRoute path="/Report/Edit" component={() => <AdminEditReport id={id} />} />

          <PrivateRoute path="/Section/Edit" component={() => <AdminEditSection id={id} />} />
          <PrivateRoute path="/Sections" component={AdminSections} />

          <PrivateRoute path="/AdviceLines/Edit" component={() => <AdminEditAdviceLine id={id} />} />
          <PrivateRoute path="/AdviceLines" component={AdminAdviceLines} />

          <PrivateRoute path="/Packages/Edit" component={() => <AdminEditPackage id={id} />} />
          <PrivateRoute path="/Packages" component={AdminPackages} />

          <PrivateRoute path="/Accounts/Edit/:ID" component={AdminEditAccount} />
          <PrivateRoute path="/Accounts" component={AdminAccounts} />

          <PrivateRoute path="/Document/Edit" component={() => <AdminEditDocument id={id} />} />
          <PrivateRoute path="/Document/New" component={AdminNewDocument} />

          <PrivateRoute path="/Refunds/Edit" component={() => <AdminEditRefund id={id} />} />
          <PrivateRoute path="/Refunds" component={AdminRefunds} />

          <PrivateRoute path={"/Courses"} component={Courses} />
          <PrivateRoute path={"/Courses/Details/:CourseID"} component={CourseDetails} />
          <PrivateRoute path={"/Courses/Details/:CourseID/Modules"} component={CourseModules} />

          <PrivateRoute path={"/Modules/:CourseID"} component={Modules} />
          <PrivateRoute path={"/Modules/Details/:ModuleID"} component={ModuleDetails} />
          <PrivateRoute path={"/Modules/Details/:ModuleID/Lessons"} component={ModuleLessons} />
          <PrivateRoute path={"/Modules/Details/:ModuleID/Workflow"} component={ModuleWorkflow} />

          <PrivateRoute path={"/Lessons/:ModuleID"} component={Lessons} />
          <PrivateRoute path={"/Lesson/Details/:LessonID"} component={LessonDetails} />
          <PrivateRoute path={"/Lesson/Details/:LessonID/Builder"} component={LessonBuilder} />
          <PrivateRoute path={"/Lesson/:LessonID/Slide/:SlideID"} component={Lesson} />
        </Router>
      </AdminNav>
    </Layout>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Component {...rest} />
}

export default AdminPortalPage;
