import React, { useEffect, useState } from 'react';
import { Package } from '../../../../Classes/Package';
import { API_AdminPackages, API_SaveSection } from '../../../../Services/ApiRoutes';
import { Fetch } from '../../../../Services/Fetch';
import * as styles from './SectionEdit.module.scss';
import { Loading } from '../../../../components/Loading/Loading';
import { Section } from '../../../../Classes/Section';

type ErrorMessage = {
  Message: string;
  Class: string;
}

interface Props {
  id: string;
}

const AdminEditSection: React.FC<Props> = ({ id }) => {
  const [sectionForm, setSectionForm] = useState<Section>({} as Section);
  const [message, setMessage] = useState<ErrorMessage>({} as ErrorMessage);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      setLoading(true);

      Fetch(`${API_AdminPackages}`)
        .then((Response: Package[]) => {
          if (Response) {
            let secData: Section | undefined = Response.find(p => p.Documents.find(d => d.SectionID === parseInt(id)))?.Documents.find(d => d.SectionID === parseInt(id))?.Section;
            if (secData) {
              setSectionForm(secData);
              setLoading(false);
            }
          }
        })
    }
  }, [id])

  const Save = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    await Fetch(`${API_SaveSection}`, JSON.parse(JSON.stringify(sectionForm))).then((res: Package) => {
      setSectionForm(res);
      setMessage({
        Class: "Success",
        Message: "Successfully updated the Section"
      } as ErrorMessage);
    })
    setLoading(false);
  }

  return (<>
    {loading ? <div className={styles.Loading}><Loading /></div> : <form className={styles.Form} onSubmit={(e) => Save(e)}>
      <div className={`${styles.Input}`}>
        <label>Title *</label>
        <input type="text" value={sectionForm.Title} onChange={(e) => setSectionForm({ ...sectionForm, Title: e.target.value })} required />
      </div>

      <div className={`${styles.Input}`}>
        <label>Description</label>
        <textarea value={sectionForm.Description} onChange={(e) => setSectionForm({ ...sectionForm, Description: e.target.value })}></textarea>
      </div>

      {
        message.Message ? <div className={`${styles.Message} ${styles[message.Class]}`}>
          <p>{message.Message}</p>
        </div> : <></>
      }

      <button type="submit">
        Save
      </button>
    </form>}
  </>);
};

export default AdminEditSection;
