import React, { useEffect, useState } from 'react';
import { Fetch } from '../../../Services/Fetch';
import * as styles from '../AdminBase.module.scss';
import { API_GetAccounts } from '../../../Services/ApiRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../../../components/Loading/Loading';
import { Report } from '../../../Classes/Reports';
import { User } from '../../../Classes/User';
import { Link } from 'gatsby';

interface Props {
  id: number;
}

const AdminReports: React.FC<Props> = ({ id }) => {
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [reports, setReports] = useState<Report[]>([] as Report[]);

  useEffect(() => {
    document.title = "PrimeHR :: Admin";

    if (id) {
      setLoading(true);

      Fetch(`${API_GetAccounts}`)
        .then((Response: User[]) => {
          if (Response) {
            let user: User | undefined = Response.find(p => p.ID === id);
            if (user && user.Reports && user.Reports.length) {
              setReports(user.Reports);
            }
            setLoading(false);
          }
        })
    }
  }, [id])

  return (<>
    <div className={styles.Search}>
      <div className={styles.SearchInput}>
        <i><FontAwesomeIcon icon={faSearch} /></i>
        <input type="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
    </div>

    <div className={styles.Table}>
      <div className={styles.Header}>
        <div className={styles.Cell}>
          <p>Question</p>
        </div>
        <div className={styles.Cell}>
          <p>Answer</p>
        </div>
        <div className={styles.Cell}>
          <p>Call Started</p>
        </div>
        <div className={styles.Cell}>
          <p>Call Ended</p>
        </div>
        <div className={styles.Cell}>
          <p>Status</p>
        </div>
      </div>
      {loading ? <Loading /> : <div className={styles.Body}>
        {
          reports.filter(r => r.Question.toLowerCase().includes(search.toLowerCase())).map(rep => {
            return <Link to={`/Admin/Report/Edit?id=${rep.ID}`} className={styles.Row}>
              <div className={styles.Cell}>
                <p>{rep.Question}</p>
              </div>
              <div className={styles.Cell}>
                <p>{rep.Answer}</p>
              </div>
              <div className={styles.Cell}>
                <p>{new Date(rep.CallStarted).toLocaleString()}</p>
              </div>
              <div className={styles.Cell}>
                <p>{new Date(rep.CallEnded).toLocaleString()}</p>
              </div>
              <div className={styles.Cell}>
                <p>{rep.Status}</p>
              </div>
            </Link>
          })
        }
      </div>}
    </div>
  </>);
};

export default AdminReports;
