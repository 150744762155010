import React, { useEffect, useState } from 'react';
import { API_GetAccounts, API_SaveAdviceReport } from '../../../../Services/ApiRoutes';
import { Fetch } from '../../../../Services/Fetch';
import * as styles from './ReportEdit.module.scss';
import { Loading } from '../../../../components/Loading/Loading';
import { User } from '../../../../Classes/User';
import { Report } from '../../../../Classes/Reports';

type ErrorMessage = {
  Message: string;
  Class: string;
}

interface Props {
  id: string;
}

const AdminEditReport: React.FC<Props> = ({ id }) => {
  const [userData, setUser] = useState<User>({} as User);
  const [reportForm, setReportForm] = useState<Report>({
    Notes: "",
    Question: "",
    Answer: ""
  } as Report);
  const [message, setMessage] = useState<ErrorMessage>({} as ErrorMessage);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      setLoading(true);

      Fetch(`${API_GetAccounts}`)
        .then((Response: User[]) => {
          if (Response) {
            let report: Report | undefined = Response.find(u => u.Reports.find(r => r.ID === parseInt(id)))?.Reports.find(r => r.ID === parseInt(id));
            if (report) {
              let user: User | undefined = Response.find(u => u.ID === report?.UserID);
              setReportForm(report);
              if (user)
                setUser(user);
              setLoading(false);
            }
          }
        })
    }
  }, [id])

  useEffect(() => {
    if (reportForm.Status && !loading)
      Save(undefined);
  }, [reportForm.Status])

  const Save = async (e: any | undefined) => {
    setLoading(true);
    if (e)
      e.preventDefault();
    let data: Report = JSON.parse(JSON.stringify(reportForm));
    data.DateAdded = new Date();

    await Fetch(`${API_SaveAdviceReport}`, data).then((res: Report) => {
      setReportForm(res);
      setMessage({
        Class: "Success",
        Message: "Successfully Updated Report"
      } as ErrorMessage);
    })
    setLoading(false);
  }

  return (<>
    {loading ? <div className={styles.Loading}><Loading /></div> : <form className={styles.Form}>
      {reportForm.CallStarted ? <>
        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Start At:</label>
          <span>{reportForm.CallStarted.toLocaleString()}</span>
        </div>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Ended At:</label>
          <span>{reportForm.CallEnded ? reportForm.CallEnded.toLocaleString() : ""}</span>
        </div>

        <div className={`${styles.Input}`}>
          <label>Question Asked:</label>
          <textarea value={reportForm.Question} onChange={(e) => setReportForm({ ...reportForm, Question: e.target.value })}></textarea>
        </div>

        <div className={`${styles.Input}`}>
          <label>PrimeHR Advice (Use your notes to fill this in, can be completed after the call):</label>
          <textarea value={reportForm.Answer} onChange={(e) => setReportForm({ ...reportForm, Answer: e.target.value })}></textarea>
        </div>

        <div className={`${styles.Input}`}>
          <label>Notes (Optional - Will help complete the "PrimeHR Advice" section):</label>
          <textarea value={reportForm.Notes} onChange={(e) => setReportForm({ ...reportForm, Notes: e.target.value })}></textarea>
        </div>

        {
          message.Message ? <div className={`${styles.Message} ${styles[message.Class]}`}>
            <p>{message.Message}</p>
          </div> : <></>
        }

        {!reportForm.CallEnded ? <button type="button" onClick={() => setReportForm({ ...reportForm, CallEnded: new Date() })}>
          End Call
        </button> : <></>}
        <button type="button" disabled={!reportForm.CallEnded} onClick={() => { setReportForm({ ...reportForm, Status: "IN PROGRESS" }); }}>
          Save and Continue
        </button>
        <button type="button" disabled={!reportForm.CallEnded} onClick={() => { setReportForm({ ...reportForm, Status: "COMPLETE" }); }}>
          Save and Finish
        </button>
      </> : <>
        <button type="submit" onClick={() => setReportForm({ ...reportForm, CallStarted: new Date() })}>
          Start Call
        </button>
      </>}
    </form>}
  </>);
};

export default AdminEditReport;
