import React, { useEffect, useContext, useState } from 'react';
import DetailsMaster from '../Config';
import * as styles from './Workflow.module.scss';
import { API_SaveLesson } from '../../../ApiRoutes';
import { Lesson } from '../../../Classes/Lesson';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Workflow } from '../../../Classes/Workflow';
import { Course } from '../../../Classes/Course';
import { CourseModule } from '../../../Classes/CourseModule';
import { faTrash, faPlusCircle, faExclamationCircle, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import { LoadingContext } from '../../../../../../Context/LoadingContext';
import { OrganisationContext } from '../../../../../../Context/UserContext';
import { Fetch } from '../../../../../../Services/Fetch';
import { GetModuleWorkflow } from '../../../Services/Workflow';
import { Panel } from '../../../Components';
import { ToastContext } from '../../../Context/ToastContext';
import { DecryptValue, EncryptValue } from '../../../../../../Services/Crypto';

const ModuleWorkflow: React.FC = (props: any) => {
  const ModuleID: string = props.ModuleID ? props.ModuleID : "";

  const { Organisation, setOrganisation } = useContext(OrganisationContext);
  const { LoadingSettings, setLoading } = useContext(LoadingContext);
  const { ToastSettings, setToastSettings } = useContext(ToastContext);
  const [lessons, setLessons] = useState<Lesson[]>([] as Lesson[]);
  const [module, setModule] = useState<CourseModule>({} as CourseModule);
  const [flow, setWorkflow] = useState<Workflow[]>([] as Workflow[]);

  const GetLessons = () => {
    let course: Course | undefined = Organisation.Courses.find(c => c.Modules?.find(m => m.ID === parseInt(DecryptValue(ModuleID))));
    if (course) {
      let module: CourseModule | undefined = course.Modules?.find(m => m.ID === parseInt(DecryptValue(ModuleID)));

      if (module) {
        setModule(module);
        setLessons(module.Lessons);
        setWorkflow(GetModuleWorkflow(module.Lessons));
      }
    }
  }

  const AddToWorkflow = (Type: "Lesson", Data: Lesson) => {
    let workflow: Workflow[] = JSON.parse(JSON.stringify(flow));
    Data.Next = 0;
    Data.Previous = workflow.length ? workflow[workflow.length - 1].Data.ID : 0;

    if (workflow.length) {
      workflow[workflow.length - 1].Data.Next = Data.ID;
    }

    workflow.push({
      Type: Type,
      Data: Data
    })
    setWorkflow(workflow)
  }

  const RemoveFromWorkflow = (index: number) => {
    let data: Workflow[] = JSON.parse(JSON.stringify(flow));
    if (data[index - 1]) {
      data[index - 1].Data.Next = data[index + 1] ? data[index + 1].Data.ID : 0;
    }

    if (data[index + 1]) {
      data[index + 1].Data.Previous = data[index - 1] ? data[index - 1].Data.ID : 0;
    }
    data.splice(index, 1);
    setWorkflow(data)
  }

  const SaveWorkflow = () => {
    let lessonList: Lesson[] = [] as Lesson[];
    let moduleData: CourseModule = JSON.parse(JSON.stringify(module));
    moduleData.Lessons = JSON.parse(JSON.stringify(flow)).filter(f => f.Type === "Lesson").map(f => {
      return (f.Data as Lesson);
    })

    setLoading({ Title: "Saving Workflow...", Show: true });

    moduleData.Lessons.map(async (lesson, ind) => {
      await Fetch(`${API_SaveLesson}`, lesson)
        .then((result) => {
          lessonList.push(result);
        }).finally(() => {
          setLoading({ ...LoadingSettings, Show: false });
        })
    })

    setOrganisation({
      ...Organisation, Courses: Organisation.Courses.map(c => {
        if (c.ID === moduleData.CourseID)
          c.Modules = c.Modules?.map(m => {
            if (m.ID === moduleData.ID)
              m.Lessons = moduleData.Lessons;
            return m;
          })
        return c;
      })
    })

    setToastSettings({
      duration: 2500,
      isOpen: true,
      message: `Successfully saved Workflow for ${moduleData.Title}`,
      onDidDismiss: () => setToastSettings({ ...ToastSettings, isOpen: false })
    })
  }

  const MoveUp = (Index: number) => {
    let list: Workflow[] = JSON.parse(JSON.stringify(flow));
    var b = list[Index - 1];
    list[Index - 1] = list[Index];
    list[Index] = b;

    list = list.map((l, i) => {
      return { ...l, Data: { ...l.Data, Next: list[i + 1] ? list[i + 1].Data.ID : 0, Previous: list[i - 1] ? list[i - 1].Data.ID : 0 } }
    })

    setWorkflow(list);
  }

  const MoveDown = (Index: number) => {
    let list: Workflow[] = JSON.parse(JSON.stringify(flow));
    var b = list[Index + 1];
    list[Index + 1] = list[Index];
    list[Index] = b;

    list = list.map((l, i) => {
      return { ...l, Data: { ...l.Data, Next: list[i + 1] ? list[i + 1].Data.ID : 0, Previous: list[i - 1] ? list[i - 1].Data.ID : 0 } }
    })

    setWorkflow(list);
  }

  useEffect(() => {
    if (Organisation.Employees)
      GetLessons();
  }, [Organisation])

  useEffect(() => {
    if (lessons)
      setWorkflow(GetModuleWorkflow(lessons));
  }, [lessons])

  return (<DetailsMaster ModuleID={parseInt(DecryptValue(ModuleID))} Page="Workflow">
    <Panel Footer={false} Title="Workflow" Width="100%" Buttons={[{
      Type: "button",
      Children: <p>Save</p>,
      Color: "Theme",
      OnClick: () => { SaveWorkflow() }
    }]}>
      <div className={styles.Main}>
        <div className={styles.Side}>
          <div className={styles.Block}>
            <h1>
              <b>Lessons</b>
              <Link to={`/Admin/Lesson/Details/${EncryptValue("0")}?ModuleID=${DecryptValue(ModuleID)}`}>
                New
              </Link>
            </h1>
            {lessons.filter(lesson => !flow.find(workflow => lesson.ID === workflow.Data.ID)).length ?
              <ul>
                {
                  lessons.filter(lesson => !flow.find(workflow => lesson.ID === workflow.Data.ID)).map((lesson) => {
                    return <li>
                      <Link to={`/Admin/Lesson/Details/${EncryptValue(lesson.ID.toString())}`}>{lesson.Title}</Link>
                      <button title="Add Lesson" onClick={() => { AddToWorkflow("Lesson", lesson); }}>
                        <i>
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </i>
                      </button>
                    </li>
                  })
                }
              </ul> :
              <div className={styles.NoContent}>
                <i>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </i>
                <p>No Unassigned Lessons</p>
              </div>}
          </div>
        </div>

        <div className={styles.Content}>
          <h1>Current Workflow</h1>
          {/* {flow.length ? <IonReorderGroup disabled={false} onIonItemReorder={(e) => doReorder(e)}> */}
          {flow.length ? <div>
            {
              flow.map((item, index) => {
                return <div className={styles.Row}>
                  <p>{index + 1}.</p>
                  {/* <IonReorder slot="start">
                    <i>
                      <FontAwesomeIcon icon={faBars} />
                    </i>
                  </IonReorder> */}
                  <span>
                    <Link to={`/Admin/${item.Type}/Details/${EncryptValue(item.Data.ID.toString())}`}>{item.Data.Title}</Link>
                    <label>Lesson</label>
                  </span>
                  <button onClick={() => RemoveFromWorkflow(index)} title={`Remove ${item.Type}`}>
                    <i>
                      <FontAwesomeIcon icon={faTrash} />
                    </i>
                  </button>
                  <button onClick={() => MoveUp(index)} title={`Move Up`}>
                    <i>
                      <FontAwesomeIcon icon={faChevronUp} />
                    </i>
                  </button>
                  <button onClick={() => MoveDown(index)} title={`Move Down`}>
                    <i>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </i>
                  </button>
                </div>
              })
            }
            {/* </IonReorderGroup> */}
          </div>
            :
            <div className={`${styles.NoContent} ${styles.Large}`}>
              <i>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </i>
              <p>No Workflow Has Been Set</p>
            </div>}
        </div>
      </div>
    </Panel>
  </DetailsMaster>);
};

export default ModuleWorkflow;
