import React, { useState, useEffect, useContext } from 'react';
import * as styles from './Builder.module.scss';
import { Slide } from '../../../Classes/Slide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailsMaster from '../Config';
import { API_SaveSlides } from '../../../ApiRoutes';
import { Lesson } from '../../../Classes/Lesson';
import { Course } from '../../../Classes/Course';
import { CourseModule } from '../../../Classes/CourseModule';
import { faEdit, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { ToastContext } from '../../../Context/ToastContext';
import { OrganisationContext } from '../../../../../../Context/UserContext';
import { AlertContext } from '../../../Context/ModalContext';
import { Panel } from '../../../Components';
import { Fetch } from '../../../../../../Services/Fetch';
import { FileUpload } from '../../../Components/FileUpload/FileUpload';
import { Button } from '../../../Components/Button/Button';
import { Editor } from '../../../Components/Editor/Editor';
import Input from '../../../../../../components/Input/input';
import { DecryptValue } from '../../../../../../Services/Crypto';

const LessonBuilder: React.FC = (props: any) => {
  const LessonID: string = props.LessonID ? props.LessonID : "";

  const slideNumber: number = 1;
  const { ToastSettings, setToastSettings } = useContext(ToastContext);
  const { Organisation, setOrganisation } = useContext(OrganisationContext);
  const { setAlert } = useContext(AlertContext);
  const [position, setPosition] = useState(slideNumber);
  const [Slides, setSlides] = useState<Slide[]>([] as Slide[]);
  const [Course, setCourse] = useState<Course>({} as Course);
  const [Module, setModule] = useState<CourseModule>({} as CourseModule);

  useEffect(() => {
    if (Organisation.Employees)
      GetLessonSlides();
  }, [Organisation])

  const GetLessonSlides = () => {
    let course: Course | undefined = Organisation.Courses.find(c => c.Modules?.find(m => m.Lessons.find(l => l.ID === parseInt(DecryptValue(LessonID)))))
    if (course) {
      setCourse(course);
      let module: CourseModule | undefined = course.Modules?.find(m => m.Lessons.find(l => l.ID === parseInt(DecryptValue(LessonID))));
      if (module) {
        setModule(module);
        let lesson: Lesson | undefined = module.Lessons.find(l => l.ID === parseInt(DecryptValue(LessonID)));
        if (lesson) {
          setSlides(lesson.Slides);
        }
      }
    }
  }

  const SaveSlides = () => {
    Fetch(`${API_SaveSlides}`, Slides)
      .then((slideResponse) => {
        setToastSettings({
          isOpen: true,
          message: "Successfully Saved",
          duration: 2500,
          onDidDismiss: () => { setToastSettings({ ...ToastSettings, isOpen: false }) }
        })
        setOrganisation({
          ...Organisation,
          Courses: Organisation.Courses.map(c => {
            if (c.ID === Course.ID)
              c.Modules = c.Modules?.map(m => {
                if (m.ID === Module.ID)
                  m.Lessons = m.Lessons.map(l => {
                    if (l.ID === parseInt(DecryptValue(LessonID)))
                      l.Slides = slideResponse;
                    return l;
                  })
                return m;
              })
            return c
          })
        })
        GetLessonSlides();
      })
  }

  const AddSlide = () => {
    let slideSet: Slide[] = JSON.parse(JSON.stringify(Slides));
    let slide: Slide = {
      ID: 0,
      Image: "",
      Title: `Slide ${(Slides.length + 1)}`,
      Content: "",
      LessonID: parseInt(DecryptValue(LessonID)),
      Position: (Slides.length + 1),
      isDeleted: false
    }

    slideSet.push(slide);
    setSlides(slideSet);
  }

  const CheckDelete = () => {
    if (Slides.find(slide => slide.isDeleted)) {
      setAlert({
        Icon: "exclamation-circle",
        OnConfirm: () => { SaveSlides() },
        Show: true,
        Title: "Delete Slide" + (Slides.filter(slide => slide.isDeleted).length > 1 ? "s" : ""),
        SubTitle: "Saving will also PERMANENTLY DELETE any slides you have selected to remove, are you sure you wish to continue?"
      })
    } else {
      SaveSlides();
    }
  }

  const SetSlideContent = (Value: string) => {
    let content: Slide | undefined = Slides.find(s => s.Position === position);

    if (content)
      setSlides(Slides.map((s: Slide) => {
        if (s.Position === content?.Position)
          return { ...s, Content: Value }
        return s
      }))
  }

  const GetSlideContent = () => {
    let content: Slide | undefined = Slides.find(s => s.Position === position);
    if (content)
      return content.Content;
    return "";
  }

  // const ReorderDashboard = (event: CustomEvent<ItemReorderEventDetail>) => {
  //   let slideData: Slide[] = event.detail.complete(Slides);

  //   let data: Slide[] = slideData.map((slide, index) => {
  //     slide.Position = (index + 1);
  //     return slide;
  //   });
  //   setSlides(data);

  //   event.detail.complete();
  // }

  const Cancel = () => {
    GetLessonSlides();
  }

  const DeleteSlide = (Slide: Slide) => {
    if (Slide.ID)
      setSlides(Slides.map((s) => {
        if (s.Position === Slide.Position)
          return { ...s, isDeleted: !Slide.isDeleted }
        return s
      }))
    else {
      let index: number = Slides.findIndex(s => s.Position === Slide.Position);
      let slides: Slide[] = JSON.parse(JSON.stringify(Slides));
      slides.splice(index, 1);
      setSlides(slides);
    }
  }

  return (<DetailsMaster LessonID={parseInt(DecryptValue(LessonID))} Page="Builder">
    <Panel Footer={false} Title="Builder" Buttons={[{
      Children: <p>Cancel</p>,
      Color: "Link",
      OnClick: () => { Cancel() }
    }, {
      Children: <p>Save</p>,
      Color: "Theme",
      OnClick: () => { CheckDelete() }
    }]} Padding={"0px"} Width={"100%"}>
      <div className={styles.Main}>
        <div className={styles.Content}>
          <div className={styles.Slides}>
            <div className={styles.SlidesInner}>
              {
                Slides.map((s: Slide) => {
                  return <div className={`${styles.Slide} ${position === s.Position ? styles.Show : ""}`}>
                    {
                      (s.Image !== "") ?
                        <>
                          <img src={s.Image} />
                          <div className={styles.SlideOverlay}>
                            <button onClick={() => {
                              setSlides(Slides.map(sld => {
                                if (s.Position === sld.Position)
                                  return { ...sld, Image: "" };
                                return sld;
                              }));
                            }}>Clear</button>
                          </div>
                        </>
                        :
                        <FileUpload OnChange={(v) => {
                          setSlides(Slides.map(sld => {
                            if (s.Position === sld.Position)
                              return { ...sld, Image: v };
                            return sld;
                          }));
                        }} />
                    }
                  </div>
                })
              }
            </div>
          </div>

          <div className={styles.Header}>
            <div className={styles.HeaderText}>
              <h1>Lesson Title</h1>
            </div>
          </div>

          <div className={styles.Body}>
            <div className={styles.BodyInner}>
              {/* <IonReorderGroup disabled={false} className={styles.Nav} onIonItemReorder={(e) => ReorderDashboard(e)}> */}
              <div className={styles.Nav}>
                {
                  Slides.map((s: Slide) => {
                    return <div className={`${styles.NavItem} ${s.Position === position ? styles.Active : ""} ${s.isDeleted ? styles.deleted : ""}`}>
                      {/* <IonReorder>
                        <i>
                          <FontAwesomeIcon icon="bars" />
                        </i>
                      </IonReorder> */}
                      <button onClick={() => { setPosition(s.Position) }}>
                        <i>
                          <FontAwesomeIcon icon={faEdit} />
                        </i>
                      </button>
                      <div className={styles.NavItemInput}>
                        <Input Disabled={s.isDeleted} Value={s.Title} Type="text" Label="" OnChange={(v) => {
                          setSlides(Slides.map((slide) => {
                            if (slide.Position === s.Position)
                              return { ...slide, Title: v };
                            return slide;
                          }));
                        }} Placeholder={''} />
                      </div>
                      <button onClick={() => { DeleteSlide(s) }}>
                        {!s.isDeleted ? <i title="Remove Slide">
                          <FontAwesomeIcon icon={faTrash} />
                        </i> :
                          <i title="Recover Slide">
                            <FontAwesomeIcon icon={faUndo} />
                          </i>}
                      </button>
                    </div>
                  })
                }
                <div className={styles.Button}>
                  <Button OnClick={() => { AddSlide() }} Color="Theme">
                    <p>Add</p>
                  </Button>
                </div>
              </div>
              {/* </IonReorderGroup> */}

              <div className={styles.BodyContent}>
                <Editor OnChange={(v) => { SetSlideContent(v) }} Value={GetSlideContent()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  </DetailsMaster>)
};

export default LessonBuilder;
