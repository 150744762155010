import React, { useEffect, useState } from 'react';
import { API_AdviceLines, API_SaveAdviceLine } from '../../../../Services/ApiRoutes';
import { Fetch } from '../../../../Services/Fetch';
import * as styles from './AdviceLineEdit.module.scss';
import { Plugins } from '@capacitor/core';
import { Loading } from '../../../../components/Loading/Loading';
import { AdviceLine } from '../../../../Classes/AdviceLine';
const { Storage } = Plugins;

type ErrorMessage = {
  Message: string;
  Class: string;
}

interface Props {
  id: string;
}

const AdminEditAdviceLine: React.FC<Props> = ({ id }) => {
  const [adviceLineData, setAdviceLine] = useState<AdviceLine>({} as AdviceLine);
  const [adviceLineForm, setAdviceLineForm] = useState<AdviceLine>({} as AdviceLine);
  const [message, setMessage] = useState<ErrorMessage>({} as ErrorMessage);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      setLoading(true);

      Fetch(`${API_AdviceLines}`)
        .then((Response: AdviceLine[]) => {
          if (Response) {
            let advLine: AdviceLine | undefined = Response.find(p => p.ID === parseInt(id));
            if (advLine) {
              setAdviceLine(advLine);
              setAdviceLineForm(advLine);
              setLoading(false);
            }
          }
        })
    }
  }, [id])

  const Save = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    await Fetch(`${API_SaveAdviceLine}`, JSON.parse(JSON.stringify(adviceLineForm))).then((res: AdviceLine) => {
      setAdviceLine(res);
      setAdviceLineForm(res);
      setMessage({
        Class: "Success",
        Message: "Successfully updated the Advice Line"
      } as ErrorMessage);
    })
    setLoading(false);
  }

  return (<>
    {loading ? <div className={styles.Loading}><Loading /></div> : <form className={styles.Form} onSubmit={(e) => Save(e)}>
      <div className={`${styles.Input}`}>
        <label>Title *</label>
        <input type="text" value={adviceLineForm.Title} onChange={(e) => setAdviceLineForm({ ...adviceLineForm, Title: e.target.value })} required />
      </div>

      <div className={`${styles.Input} ${styles.Half}`}>
        <label>Month Price *</label>
        <input type="text" value={adviceLineForm.MonthPrice} onChange={(e) => setAdviceLineForm({ ...adviceLineForm, MonthPrice: e.target.value })} required />
      </div>

      <div className={`${styles.Input} ${styles.Half}`}>
        <label>Year Price *</label>
        <input type="text" value={adviceLineForm.YearPrice} onChange={(e) => setAdviceLineForm({ ...adviceLineForm, YearPrice: e.target.value })} required />
      </div>

      {
        message.Message ? <div className={`${styles.Message} ${styles[message.Class]}`}>
          <p>{message.Message}</p>
        </div> : <></>
      }

      <button type="submit">
        Save
      </button>
    </form>}
  </>);
};

export default AdminEditAdviceLine;
