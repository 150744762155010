import React, { useEffect, useState } from 'react';
import { Fetch } from '../../../Services/Fetch';
import * as styles from '../AdminBase.module.scss';
import { API_AdminContacts } from '../../../Services/ApiRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../../../components/Loading/Loading';
import { Contact } from '../../../Classes/Contact';
import { Link } from 'gatsby';

const AdminContacts: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [contacts, setContacts] = useState<Contact[]>([] as Contact[]);

  useEffect(() => {
    document.title = "PrimeHR :: Admin";

    Fetch(`${API_AdminContacts}`)
      .then((Response: Contact[]) => {
        if (Response) {

          setContacts(Response);
          setLoading(false);
        }
      })
  }, [])

  return (<>
    <div className={styles.Search}>
      <div className={styles.SearchInput}>
        <i><FontAwesomeIcon icon={faSearch} /></i>
        <input type="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
    </div>

    <div className={styles.Table}>
      <div className={styles.Header}>
        <div className={styles.Cell}>
          <p>Full Name</p>
        </div>
        <div className={styles.Cell}>
          <p>Phone</p>
        </div>
        <div className={styles.Cell}>
          <p>Email</p>
        </div>
        <div className={styles.Cell}>
          <p>Email</p>
        </div>
        <div className={styles.Cell}>
          <p>Company</p>
        </div>
        <div className={styles.Cell}>
          <p>Message</p>
        </div>
      </div>
      {loading ? <Loading /> : <div className={styles.Body}>
        {
          contacts.filter(s => s.FullName.toLowerCase().includes(search.toLowerCase())).map(sec => {
            return <Link to={`/Admin/Section/Edit?id=${sec.ID}`} className={styles.Row}>
              <div className={styles.Cell}>
                <p>{sec.FullName}</p>
              </div>
              <div className={styles.Cell}>
                <p>{sec.Phone}</p>
              </div>
              <div className={styles.Cell}>
                <p>{sec.Email}</p>
              </div>
              <div className={styles.Cell}>
                <p>{sec.Company}</p>
              </div>
              <div className={styles.Cell}>
                <p>{sec.Message}</p>
              </div>
            </Link>
          })
        }
      </div>}
    </div>
  </>);
};

export default AdminContacts;
