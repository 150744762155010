import React, { useContext, useEffect, useState } from 'react';
import * as styles from './Course.module.scss';
import { Course } from '../../Classes/Course';
import CourseSidebar from '../../Components/Sidebar/Sidebar';
import { Link, navigate } from 'gatsby';
import { OrganisationContext, UserContext } from '../../../../../Context/UserContext';
import { BreadCrumb, Panel } from '../../Components';
import { EncryptValue } from '../../../../../Services/Crypto';

interface Props {
    CourseID: number;
    Page: "Details" | "Modules" | "Resources" | "Forms";
}

const DetailsMaster: React.FC<Props> = ({ children, CourseID, Page }) => {
    const { Organisation } = useContext(OrganisationContext);
    const [course, setCourse] = useState({} as Course);

    useEffect(() => {
        if (Organisation.Employees) {
            if (CourseID) {
                let Course: Course | undefined = Organisation.Courses.find(c => c.ID === CourseID);

                if (Course) {
                    setCourse(Course)
                } else {
                    navigate(`/Portal/Courses`);
                }
            }
        }
    }, [Organisation])

    return (<Panel Footer={false} Title="Course Settings" Width="100%" Buttons={[]} Padding="0px">
        <div className={styles.Permissions}>
            <CourseSidebar>
                <div className={styles.SidebarLink}>
                    <Link className={Page === "Details" ? styles.Active : ""} to={`/Admin/Courses/Details/${EncryptValue(CourseID.toString())}`}>
                        Details
                    </Link>
                </div>

                {CourseID ? <>
                    <div className={styles.SidebarLink}>
                        <Link className={Page === "Modules" ? styles.Active : ""} to={`/Admin/Courses/Details/${EncryptValue(CourseID.toString())}/Modules`}>
                            Modules
                        </Link>
                    </div>
                </> : <></>}
            </CourseSidebar>

            <div className={styles.PermissionsContent}>
                <BreadCrumb CurrentPage={!CourseID ? "New Course" : (Page === "Details" ? course.Title : Page)} Links={Page !== "Details" && course.ID ? [{
                    Text: 'Courses',
                    To: `Admin/Courses`
                }, {
                    Text: `${course.Title ? course.Title : "..."}`,
                    To: `Admin/Courses/Details/${EncryptValue(course.ID.toString())}`
                }] : [{
                    Text: 'Courses',
                    To: `Admin/Courses`
                }]} />
                {children}
            </div>
        </div>
    </Panel>);
};

export default DetailsMaster