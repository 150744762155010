import React, { useContext, useEffect, useState } from 'react';
import { API_AdminGetEmployees, API_GetAccounts, API_SaveUser } from '../../../../Services/ApiRoutes';
import { Fetch } from '../../../../Services/Fetch';
import * as styles from './AccountEdit.module.scss';
import { Loading } from '../../../../components/Loading/Loading';
import { User } from '../../../../Classes/User';
import AdminReports from '../../Reports/Reports';
import { Link } from 'gatsby';
import { Employee } from '../../../../Classes/Employee';
import { UserContext } from '../../../../Context/UserContext';

const AdminEditAccount: React.FC = (props: any) => {
  const ID: string = props.ID ? props.ID : "";

  const { Language } = useContext(UserContext);
  const [userForm, setUserForm] = useState<User>({} as User);
  const [employees, setEmployees] = useState<Employee[]>([] as Employee[]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (ID) {
      setLoading(true);

      Fetch(`${API_GetAccounts}`)
        .then((Response: User[]) => {
          if (Response) {
            let user: User | undefined = Response.find(p => p.ID === parseInt(ID));

            if (user) {
              setUserForm(user);

              Fetch(`${API_AdminGetEmployees}`, parseInt(ID))
                .then((Response: Employee[]) => {
                  if (Response) {
                    setEmployees(Response);
                    setLoading(false);
                  }
                })
            }
          }
        })
    }
  }, [ID])

  const ChangePackage = (PackageID: number) => {
    let userData: User = JSON.parse(JSON.stringify(userForm));
    userData.PackageID = PackageID;

    Fetch(API_SaveUser, userData).then((response) => {
      setUserForm(response);
    })
  }

  return (<>
    {loading ? <div className={styles.Loading}><Loading /></div> : <>
      <div className={styles.Form}>
        <div className={styles.FormHeader}>
          {
            (userForm.Subscription && userForm.Subscription.hasAdviceLine) ?
              <Link to={`/Admin/Report/New/${ID}`}>Create Report</Link> :
              <></>
          }
        </div>

        <h1>{Language.Application_Employees.PersonalInformation}</h1>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Title</label>
          <span>{userForm.Title}</span>
        </div>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Forename</label>
          <span>{userForm.Forename}</span>
        </div>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Surname</label>
          <span>{userForm.Surname}</span>
        </div>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Email</label>
          <span>{userForm.Email}</span>
        </div>

        <h1>Contact Information</h1>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Email</label>
          <span>{userForm.Email}</span>
        </div>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Phone</label>
          <span>{userForm.Phone}</span>
        </div>

        <h1>Company Information</h1>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Company</label>
          <span>{userForm.Company}</span>
        </div>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Have Logged In</label>
          <span>{userForm.WelcomeComplete ? "Yes" : "No"}</span>
        </div>

        <h1>Package Information</h1>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Package *</label>
          <select value={userForm.PackageID} onChange={(e) => ChangePackage(parseInt(e.target.value))}>
            <option value="5">Custom (Equivalent to Gold but with unlimited Employees)</option>
            <option value="4">Consultant</option>
            <option value="3">Gold</option>
            <option value="2">Silver</option>
            <option value="1">Bronze</option>
          </select>
        </div>

        <div className={`${styles.Input} ${styles.Half}`}>
          <label>Employees</label>
          <span>{employees.filter((e) => !e.OffboardingComplete).length} Active of {employees.length} Total Employees</span>
        </div>
      </div>

      {(userForm.Subscription && userForm.Subscription.hasAdviceLine) || userForm.Reports.length ? <AdminReports id={parseInt(ID)} /> : <></>}
    </>
    }
  </>);
};

export default AdminEditAccount;
