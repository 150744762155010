// extracted by mini-css-extract-plugin
export var Active = "Documents-module--Active--ab144";
export var Documents = "Documents-module--Documents--9799b";
export var DocumentsInner = "Documents-module--DocumentsInner--7561d";
export var Filter = "Documents-module--Filter--d6703";
export var Loading = "Documents-module--Loading--8bc48";
export var Message = "Documents-module--Message--50b11";
export var Nav = "Documents-module--Nav--77fef";
export var NavInner = "Documents-module--NavInner--d4710";
export var Search = "Documents-module--Search--e025b";
export var SearchInput = "Documents-module--SearchInput--cd575";
export var Section = "Documents-module--Section--bb36c";
export var SectionHeader = "Documents-module--SectionHeader--bdca7";
export var SectionInner = "Documents-module--SectionInner--3586d";
export var loading = "Documents-module--loading--df303";