import React, { useState } from 'react';
import * as styles from './Editor.module.scss';
import ContentEditable from 'react-contenteditable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library, IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBold, faItalic, faStrikethrough, faUnderline, faSubscript, faSuperscript, faAlignLeft, faAlignJustify, faAlignCenter, faAlignRight, faListOl, faListUl, faUndo, faRedo, faLink, faUnlink, faFont, faRemoveFormat, faGripLines } from '@fortawesome/free-solid-svg-icons';
library.add(faGripLines, faBold, faItalic, faStrikethrough, faUnderline, faSubscript, faSuperscript, faAlignLeft, faAlignJustify, faAlignCenter, faAlignRight, faListOl, faListUl, faUndo, faRedo, faLink, faUnlink, faFont, faRemoveFormat);

type button = {
    Icon: IconProp;
    Title: string;
    OnClick: () => void;
}

interface Props {
    OnChange: (Content: string) => void;
    Value: string | undefined;
    Height?: string;
    Disabled?: boolean;
}

export const Editor: React.FC<Props> = ({ OnChange, Value, Height, Disabled }) => {
    const buttons: button[] = [{
        Icon: "bold",
        Title: "Bold",
        OnClick: () => Bold()
    }, {
        Icon: "italic",
        Title: "Italic",
        OnClick: () => Italic()
    }, {
        Icon: "strikethrough",
        Title: "Strikethrough",
        OnClick: () => StrikeThrough()
    }, {
        Icon: "underline",
        Title: "Underline",
        OnClick: () => Underline()
    }, {
        Icon: "subscript",
        Title: "Subscript",
        OnClick: () => Subscript()
    }, {
        Icon: "superscript",
        Title: "Superscript",
        OnClick: () => Superscript()
    }, {
        Icon: "align-left",
        Title: "Align Left",
        OnClick: () => AlignLeft()
    }, {
        Icon: "align-center",
        Title: "Align Center",
        OnClick: () => AlignCenter()
    }, {
        Icon: "align-right",
        Title: "Align Right",
        OnClick: () => AlignRight()
    }, {
        Icon: "align-justify",
        Title: "Justify Content",
        OnClick: () => Justify()
    }, {
        Icon: "list-ol",
        Title: "Bullet List",
        OnClick: () => Orderedlist()
    }, {
        Icon: "list-ul",
        Title: "Bullet List",
        OnClick: () => Unorderedlist()
    }, {
        Icon: "undo",
        Title: "Undo",
        OnClick: () => Undo()
    }, {
        Icon: "redo",
        Title: "Redo",
        OnClick: () => Redo()
    },
    // {
    //     Icon: "link",
    //     Title: "Add Hyperlink",
    //     OnClick: () => Link()
    // }, {
    //     Icon: "unlink",
    //     Title: "Remove Hyperlink",
    //     OnClick: () => Unlink()
    // }, {
    //     Icon: "font",
    //     Title: "Apply Color",
    //     OnClick: () => FontColor()
    // },
    {
        Icon: "grip-lines",
        Title: "LineBreak",
        OnClick: () => LineBreak()
    }, {
        Icon: "remove-format",
        Title: "Remove Format",
        OnClick: () => Clear()
    }]

    // const [color, setColor] = useState("#000000");
    // const [url, setUrl] = useState("");

    const Bold = () => {
        document.execCommand('bold');
    }

    const Italic = () => {
        document.execCommand('italic');
    }

    const StrikeThrough = () => {
        document.execCommand('strikeThrough');
    }

    const Underline = () => {
        document.execCommand('underline');
    }

    const Subscript = () => {
        document.execCommand('subscript');
    }

    const Superscript = () => {
        document.execCommand('superscript');
    }

    const AlignLeft = () => {
        document.execCommand('justifyLeft');
    }

    const AlignRight = () => {
        document.execCommand('justifyLeft');
    }

    const AlignCenter = () => {
        document.execCommand('justifyCenter');
    }

    const Justify = () => {
        document.execCommand('justifyFull');
    }

    const Orderedlist = () => {
        document.execCommand('insertOrderedList');
    }

    const Unorderedlist = () => {
        document.execCommand('insertUnorderedList');
    }

    const Undo = () => {
        document.execCommand('undo');
    }

    const Redo = () => {
        document.execCommand('redo');
    }

    // const Link = () => {
    //     document.execCommand('createLink', false, url);
    // }

    // const Unlink = () => {
    //     document.execCommand('unlink');
    // }

    // const FontColor = () => {
    //     document.execCommand('foreColor', false, color);
    // }

    const LineBreak = () => {
        document.execCommand('insertHorizontalRule');
    }

    const Clear = () => {
        document.execCommand('removeFormat');
    }

    return (<div className={styles.Editor}>
        <div className={`${styles.EditorHeader} ${Disabled ? styles.Disabled : ""}`}>
            {
                buttons.map((button) => {
                    return <button type="button" title={button.Title} onClick={() => { button.OnClick() }}>
                        <i>
                            <FontAwesomeIcon icon={button.Icon} />
                        </i>
                    </button>
                })
            }
        </div>
        <div className={styles.EditorBody} style={Height ? { height: `${Height}` } : {}}>
            <ContentEditable disabled={Disabled} className={styles.EditorContent} html={Value ? Value : ""} onChange={(v) => { OnChange(v.target.value) }} />
        </div>
        <div className={styles.EditorFooter}>
            <p>{Value ? Value.length : 0} Characters</p>
        </div>
    </div>);
}