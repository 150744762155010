import React, { useEffect, useState } from 'react';
import { API_AdminRefunds, API_BaseURL, API_GetAccounts, API_SaveRefund } from '../../../../Services/ApiRoutes';
import { Fetch } from '../../../../Services/Fetch';
import * as styles from './RefundEdit.module.scss';
import { Loading } from '../../../../components/Loading/Loading';
import { Refund } from '../../../../Classes/Refund';
import { User } from '../../../../Classes/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

type ErrorMessage = {
  Message: string;
  Class: string;
}

interface Props {
  id: string;
}

const AdminEditRefund: React.FC<Props> = ({ id }) => {
  const isDev: boolean = API_BaseURL !== "/api/";
  const [refundData, setRefund] = useState<Refund>({} as Refund);
  const [refundForm, setRefundForm] = useState<Refund>({} as Refund);
  const [message, setMessage] = useState<ErrorMessage>({} as ErrorMessage);
  const [loading, setLoading] = useState<boolean>(true);
  const [accounts, setAccounts] = useState<User[]>([] as User[]);
  const [account, setAccount] = useState<User>({} as User);

  useEffect(() => {
    if (id) {
      setLoading(true);
      GetData();
    }
  }, [id]);

  useEffect(() => {
    if (refundForm.UserID && accounts.length) {
      let user: User | undefined = accounts.find(a => a.ID === refundForm.UserID);
      if (user)
        setAccount(user);
    }
  }, [refundData, accounts]);

  const GetData = async () => {
    await Fetch(`${API_AdminRefunds}`)
      .then((Response: Refund[]) => {
        if (Response) {
          let refData: Refund | undefined = Response.find(p => p.ID === parseInt(id));
          if (refData) {
            setRefund(refData);
            setRefundForm(refData);
          }
        }
      })

    await Fetch(`${API_GetAccounts}`)
      .then((Response: User[]) => {
        if (Response) {
          setAccounts(Response);
        }
      })

    setLoading(false);
  }

  const Save = async (Status: string) => {
    setLoading(true);
    let data: Refund = JSON.parse(JSON.stringify(refundForm));
    data.Status = Status;
    await Fetch(`${API_SaveRefund}`, data).then((res: Refund) => {
      setRefund(res);
      setRefundForm(res);
      setMessage({
        Class: "Success",
        Message: "Successfully updated the Refund"
      } as ErrorMessage);
    })
    setLoading(false);
  }

  return (<>
    {loading ? <div className={styles.Loading}><Loading /></div> : <div className={styles.Form}>
      <button type="button" onClick={() => Save("INPROGRESS")}>
        Status : INPROGRESS
      </button>

      <button type="button" onClick={() => Save("COMPLETED")}>
        Status : COMPLETED
      </button>

      <div className={`${styles.Input} ${styles.Half}`}>
        <label>Amount</label>
        <span>£{parseInt(refundForm.Amount) / 100}</span>
      </div>

      <div className={`${styles.Input} ${styles.Half}`}>
        <label>GoCardless Reference</label>
        <span>{refundForm.Reference}</span>
        <a className={styles.Icon} title={`Open GoCardless : ${refundForm.Reference}`} target="_blank" href={`https://${isDev ? "manage-" : ""}sandbox.gocardless.com/payments/${refundForm.Reference}`}>
          <i>
            <FontAwesomeIcon icon={faLink} />
          </i>
        </a>
      </div>

      <div className={`${styles.Input} ${styles.Half}`}>
        <label>Request On</label>
        <span>{new Date(refundForm.RequestDate).toLocaleString()}</span>
      </div>

      <div className={`${styles.Input} ${styles.Half}`}>
        <label>Refund By</label>
        <span>{new Date(refundForm.RefundBy).toLocaleString()}</span>
      </div>

      <div className={`${styles.Input} ${styles.Half}`}>
        <label>Status</label>
        <span>{refundForm.Status}</span>
      </div>

      <div className={`${styles.Input} ${styles.Half}`}>
        <label>Requested By</label>
        <Link to={`/Admin/Account/Edit?id=${refundForm.UserID}`}>{account.Title}. {account.Forename} {account.Surname}</Link>
      </div>

      {
        message.Message ? <div className={`${styles.Message} ${styles[message.Class]}`}>
          <p>{message.Message}</p>
        </div> : <></>
      }

      <button type="submit">
        Save
      </button>
    </div>}
  </>);
};

export default AdminEditRefund;
