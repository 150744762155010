// extracted by mini-css-extract-plugin
export var Checkbox = "RefundEdit-module--Checkbox--e420f";
export var Error = "RefundEdit-module--Error--86d32";
export var Form = "RefundEdit-module--Form--ec99f";
export var Half = "RefundEdit-module--Half--438ce";
export var Icon = "RefundEdit-module--Icon--1f120";
export var Input = "RefundEdit-module--Input--e6d22";
export var Loading = "RefundEdit-module--Loading--8880e";
export var Message = "RefundEdit-module--Message--3cd26";
export var Success = "RefundEdit-module--Success--17e66";
export var Third = "RefundEdit-module--Third--07009";