import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { OrganisationContext, PackageContext, UserContext } from '../../Context/UserContext';
import { API_Organisation, API_UpdateCache } from '../../Services/ApiRoutes';
import { Fetch } from '../../Services/Fetch';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { Organisation } from '../../Classes/Employee';
import * as styles from './AdminNav.module.scss';
import { Package } from '../../Classes/Package';

interface Props {
  PackageData?: Package[];
  children?: any
}

const AdminNav: React.FC<Props> = ({ PackageData, children }) => {
  const { User } = useContext(UserContext);
  const { setOrganisation } = useContext(OrganisationContext);
  const { setPackages } = useContext(PackageContext);
  const [Page, setPage] = useState<string>("");
  const [updating, setUpdating] = useState<boolean>(false);

  useEffect(() => {
    document.title = `PrimeHR :: Admin :: ${Page}`;
  }, [Page]);

  useMemo(() => {
    if (PackageData)
      setPackages(PackageData);
  }, [])

  useEffect(() => {
    if (User.ID) {
      if (!window.location.pathname.toLowerCase().startsWith("/employee/portal/") && User.isEmployee)
        navigate("/Employee/Portal/");
      if (!window.location.pathname.toLowerCase().startsWith("/portal/") && !User.isEmployee && !User.isAdmin)
        navigate("/Portal/");

      GetOrganisation();
    }
  }, [User]);

  const UpdateCache = async () => {
    setUpdating(true);
    await Fetch(`${API_UpdateCache}`);
    setUpdating(false);
  }

  const GetOrganisation = async () => {
    await Fetch(`${API_Organisation}`, {
      CustomerID: User.CustomerID,
      CompanyID: User.Owner,
      PackageID: User.PackageID
    }).then(async (Response: Organisation) => {
      setOrganisation(Response);
    });
  }

  return (<>
    <div className={styles.Nav}>
      {User.ID ? <div className={styles.NavInner}>
        <button onClick={() => UpdateCache()}>
          {updating ? <i>
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          </i> : <></>}
          Update Cache
        </button>

        <Link partiallyActive={false} to={"/Admin/"} activeClassName={styles.Active}>Documents</Link>
        <Link to={"/Admin/Accounts"} activeClassName={styles.Active}>Accounts</Link>
        <Link to={"/Admin/Packages"} activeClassName={styles.Active}>Packages</Link>
        <Link to={"/Admin/AdviceLines"} activeClassName={styles.Active}>Advice Lines</Link>
        <Link to={"/Admin/Sections"} activeClassName={styles.Active}>Sections</Link>
        <Link to={"/Admin/Contacts"} activeClassName={styles.Active}>Contacts</Link>
        <Link to={"/Admin/Refunds"} activeClassName={styles.Active}>Refunds</Link>
        <Link to={"/Admin/Courses"} activeClassName={styles.Active}>Courses</Link>
      </div> : <></>}

      <div className={styles.Main}>
        <Breadcrumb Trail={[
          { To: `/Admin/`, Text: `Admin` },
          { To: ``, Text: Page }
        ]} />

        <div className={styles.MainInner}>
          {children}
        </div>
      </div>
    </div>
  </>);
};

export default AdminNav;
