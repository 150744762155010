// extracted by mini-css-extract-plugin
export var Button = "DocumentEdit-module--Button--6b6bd";
export var Error = "DocumentEdit-module--Error--b037a";
export var Form = "DocumentEdit-module--Form--4542f";
export var Half = "DocumentEdit-module--Half--b361c";
export var Input = "DocumentEdit-module--Input--5e1ba";
export var Loading = "DocumentEdit-module--Loading--cbb9b";
export var Message = "DocumentEdit-module--Message--cce7b";
export var Param = "DocumentEdit-module--Param--27a38";
export var Parameters = "DocumentEdit-module--Parameters--450b7";
export var ParametersInner = "DocumentEdit-module--ParametersInner--8e19b";
export var Success = "DocumentEdit-module--Success--c2711";
export var Third = "DocumentEdit-module--Third--55b83";