import React, { useState } from 'react';
import { Loading } from '../components/Loading/Loading';
import { LoadingSettings } from '../components/Loading/Classes/LoadingSettings';

interface LoadingContextData {
    LoadingSettings: LoadingSettings;
    setLoading: (Loading: LoadingSettings) => void
}

export const LoadingContext = React.createContext<LoadingContextData>({
    LoadingSettings: {} as LoadingSettings,
    setLoading: () => { }
});

export const LoadingComponent: React.FC = ({ children }) => {
    const [LoadingSettings, setLoading] = useState({} as LoadingSettings);
    const value = { LoadingSettings, setLoading };

    return (<LoadingContext.Provider value={value}>
        <Loading />
        {children}
    </LoadingContext.Provider>)
}